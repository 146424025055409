<template>
  <div>

    <b-modal centered v-model="showingTagModal" header-close-content="<i class='fas fa-times color-white'></i>">
      <template #modal-title>
        <h4 class="mx-1 text-white">Add a new Tag or Tags</h4>
      </template>
      <div>
        <input v-model="newModalTag" @keyup.enter="addModalTag" placeholder="Enter Tag" class="form-control" />
        <div class="mt-3">
          <b-row align-h="center">
            <b-col v-for="(tag, index) in modalTags" :key="index" class="mt-1">
              <span class="tag-pill badge badge-primary mr-2">
                {{ tag }}
                <span class="tag-remove" @click="removeModalTag(index)">x</span>
              </span>
            </b-col>
          </b-row>
        </div>
        <p class="mt-1">
          Hit Enter to add multiple tags, then click Save to update.
        </p>
      </div>
      <div slot="modal-footer" class="d-flex justify-content-between">
        <button class="btn rounded-pill bg-yellow mx-1" @click="saveModalTags"
          :disabled="modalTags.length === 0 && newModalTag === ''">
          Save
        </button>
        <button class="btn btn-secondary rounded-pill" @click="closeTagModal">
          Cancel
        </button>
      </div>
    </b-modal>
    <b-modal centered v-model="showingPathologyModal" header-close-content="<i class='fas fa-times color-white'></i>">
      <template #modal-title>
        <h4 class="mx-1 text-white">Add a new {{primaryPath ? 'Primary' : 'Secondary'}} Pathology or Pathologies</h4>
      </template>
      <div>
        <!-- <b-form-checkbox class="mb-1" v-model="primaryPath">Primary Pathologies</b-form-checkbox> -->
        <div class="input-group">
          <input v-model="newModalPathology.label" placeholder="Enter Pathology Label" list="pathAutoComplete"
            @keyup.enter="addModalPathology" class="form-control mr-2" />
          <datalist id="pathAutoComplete">
            <option v-for="(val, ind) in pathSuggestValues" :key="val + ind"
              :value="val[0].toUpperCase() + val.substring(1)"></option>
          </datalist>
        </div>
        <div class="mt-3">
          <b-row align-h="center">
            <b-col v-for="(pathology, index) in modalPathologies" :key="index" class="mt-1">
              <span class="pathology-pill badge badge-primary mr-2" :style="{ backgroundColor: pathology.color }">
                {{ pathology.label }}
                <span class="tag-remove" @click="removeModalPathology(index)">x</span>
              </span>
            </b-col>
          </b-row>
        </div>
        <p class="mt-1">
          Hit Enter to add multiple pathologies, then click Save to update.
        </p>
      </div>
      <div slot="modal-footer" class="d-flex justify-content-between">
        <button class="btn rounded-pill bg-yellow mx-1" @click="saveModalPathologies"
          :disabled="modalPathologies.length === 0 && newModalPathology.label === ''">
          Save
        </button>
        <button class="btn btn-secondary rounded-pill" @click="closePathologyModal">
          Cancel
        </button>
      </div>
    </b-modal>
    <!-- Diagnosis Modal -->
    <b-modal centered v-model="showingDiagnosisModal" header-close-content="<i class='fas fa-times color-white'></i>">
      <template #modal-title>
        <h4 class="mx-1 text-white">Add a new Diagnosis</h4>
      </template>
      <div>
        <!-- <b-form-checkbox class="mb-1" v-model="primaryDia">Primary Diagnoses</b-form-checkbox> -->
        <input v-model="newModalDiagnosis.label" placeholder="Enter Diagnoses" @keyup.enter="addModalDiagnosis"
          class="form-control" />
        <div class="mt-3">
          <b-row align-h="center">
            <b-col v-for="(diagnosis, index) in modalDiagnoses" :key="index">
              <span class="diagnosis-pill badge badge-primary mr-2">
                {{ diagnosis.label }}
                <span class="diagnosis-remove" @click="removeModalDiagnosis(index)">x</span>
              </span>
            </b-col>
          </b-row>
        </div>
        <p class="mt-1">
          Hit Enter to add multiple Diagnoses, then click Save to update.
        </p>
      </div>
      <div slot="modal-footer" class="d-flex justify-content-between">
        <button class="btn rounded-pill bg-yellow mx-1" @click="saveModalDiagnoses"
          :disabled="modalDiagnoses.length === 0 && newModalDiagnosis.label === ''">
          Save
        </button>
        <button class="btn btn-secondary rounded-pill" @click="closeDiagnosisModal">
          Cancel
        </button>
      </div>
    </b-modal>
    <b-card-body class="h-100 p-0">
      <b-tabs card pills nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y h-100" fill
        v-model="tabIndex">
        <b-tab title="Tags" Active>
          <p class="my-2">
            Welcome to the Project Setup section. Customize your project's attributes
            with the following steps: tags, pathologies and diagnoses.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="card border-cards" :key="tagRkey">
                <div class="card-header bg-navy">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="color-white">
                      <b-icon icon="tag"></b-icon>
                      Tags
                    </h6>
                    <div class="d-flex justify-content-between align-items-center" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                      <button class="btn btn-sm bg-yellow ml-2 rounded-pill" @click="showTagModal">
                        <b-icon class="color-black" icon="plus"></b-icon>
                      </button>
                      <!-- <button class="btn btn-sm bg-yellow ml-2 rounded-pill" @click="toggleEditTags"
                        :disabled="project.tags.length === 0">
                        <b-icon class="color-black" icon="pencil-fill"></b-icon>
                      </button> -->
                    </div>
                  </div>
                </div>
                <div class="card-body bg-dark-navy">
                  <!-- Display Tags or Message -->
                  <div v-if="editMode || project.tags.length > 0">
                    <div v-for="(tag, index) in project.tags" :key="index.toString() + tagEditing[index]" class="mb-1">
                      <div v-if="tagEditing[index]">
                        <div class="tag-edit-container">
                          <b-row class="d-flex justify-content-between align-items-center  pt-2">
                            <b-col cols="11"> <input class="form-control" :value="editedTags[index] !== undefined
                              ? editedTags[index]
                              : tag
                              " @input="updateEditedTag(index, $event.target.value)" /></b-col>
                            <b-col cols="1"><span class="color-white tag-remove" @click="deleteTag(index)">
                              <b-icon class="color-white" icon="trash-fill"></b-icon>
                            </span></b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-else >
                      <b-row class="color-white tag-pill mb-0 pb-0" align-h="between">
                        <div>{{ tag }}</div>
                      <button  class="btn btn-sm bg-yellow ml-2 rounded-pill m-1 mt-0" @click="setTagEdit(index)"
                        :disabled="project.tags.length === 0 || editMode"
                        v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                        <b-icon class="color-black" icon="pencil-fill"></b-icon>
                      </button>
                    </b-row>
                  </div>

                    </div>
                  </div>
                  <div v-else class="no-data-message">
                    <b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon>
                    <p>No tags added yet. Add some tags to get started.</p>
                  </div>
                </div>

                <div v-if="editMode" class="py-2 card-footer footer-buttons">
                  <button class="btn rounded-pill btn-sm btn-secondary mr-1" @click="cancelEditingTags">
                    Cancel
                  </button>
                  <button class="btn btn-sm rounded-pill bg-yellow color-black" @click="saveEditedTags">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Pathologies" Active>
            <b-row class="ml-2" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist') && !(project.version === 2)">
              <b-form-checkbox v-model="enable_freehand">
                Enable Freehand/Segmentation
              </b-form-checkbox>

              <button class="btn btn-sm bg-yellow rounded-pill color-black ml-2" @click="saveFreehand">
                    Save
                  </button>
            </b-row>
          <div class="row">
            <div class="col-6">
              <div class="card border-cards" :key="pathRkey">
                <div class="card-header bg-navy">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="color-white">
                      <b-icon icon="thermometer-half"></b-icon>
                      Primary Pathologies
                    </h6>

                    <div class="d-flex justify-content-between align-items-center" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                      <button class="btn btn-sm bg-yellow ml-2 rounded-pill" @click="showPathologyModal(true)"
                        v-if="!editPathologies">
                        <b-icon class="color-black" icon="plus"></b-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body bg-dark-navy">
                  <div v-if="editPathologies || this.project.pathologies.length > 0">
                    <div v-for="(pathology, index) in project.pathologies" :key="index" class="mb-1">
                      <div v-if="pathology.primary">
                      <div v-if="pathEditing[index]">
                        <div class="tag-edit-container">
                          <b-row class="d-flex justify-content-between align-items-center  pt-2">
                            <b-col cols="8">
                              <input class="form-control" :value="editedPathologies[index].label" @input="
                              updateEditedPathology(
                                index,
                                'label',
                                $event.target.value
                              )
                              " />
                            </b-col>
                            <b-col  cols="1"><input type="color" v-model="editedPathologies[index].color"
                              class="form-control-pathology-color" /></b-col>
                            <b-col cols="1"><span class="color-white tag-remove" @click="deletePathology(index)">
                              <b-icon class="color-white" icon="trash-fill"></b-icon>
                            </span></b-col>
                            <b-col  cols="2"><span>
                              <b-form-checkbox class="mb-1" v-model="primaryPath">Primary</b-form-checkbox>
                            </span></b-col>
                          </b-row>
                        </div>
                      </div>
                      <!-- pathology.primary ? '#aaa;' : '#ddd;'} -->
                      <b-row v-else class="color-white pathology-pill justify-content-between align-items-center mb-0 pb-0" :style="{borderColor: pathology.primary ? '#FFC848' :'#15385b'}"  align-h="between">
                        <b-col  cols="9"><div>{{ pathology.label }}</div></b-col>
                        <b-col cols="1"><div class="pathology-color" :style="{ backgroundColor: pathology.color }"></div></b-col>
                        <b-col cols="2" align-self="end"><button :disabled="editPathologies" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')"
                          class="btn btn-sm bg-yellow ml-2 rounded-pill mb-1" @click="setPathEdit(index)">
                          <b-icon class="color-black" icon="pencil-fill"></b-icon>
                        </button></b-col>
                      </b-row>
                      </div>
                    </div>
                  </div>
                  <div v-else class="no-data-message">
                    <b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon>
                    <p>
                      No pathologies added yet. Add some pathologies to get started.
                    </p>
                  </div>
                </div>

                <div v-if="editPathologies" class="py-2 card-footer footer-buttons">
                  <button class="btn btn-sm btn-secondary mr-1 rounded-pill" @click="cancelEditingPathologies">
                    Cancel
                  </button>
                  <button class="btn btn-sm bg-yellow rounded-pill color-black" @click="saveEditedPathologies">
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card border-cards" :key="pathRkey">
                <div class="card-header bg-navy">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="color-white">
                      <b-icon icon="thermometer-half"></b-icon>
                      Secondary Pathologies
                    </h6>

                    <div class="d-flex justify-content-between align-items-center" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                      <button class="btn btn-sm bg-yellow ml-2 rounded-pill" @click="showPathologyModal(false)"
                        v-if="!editPathologies">
                        <b-icon class="color-black" icon="plus"></b-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body bg-dark-navy">
                  <div v-if="editPathologies || this.project.pathologies.length > 0">
                    <div v-for="(pathology, index) in project.pathologies" :key="index" class="mb-1">
                      <div v-if="!pathology.primary">
                      <div v-if="pathEditing[index]">
                        <div class="tag-edit-container">
                          <b-row class="d-flex justify-content-between align-items-center  pt-2">
                            <b-col cols="8">
                              <input class="form-control" :value="editedPathologies[index].label" @input="
                              updateEditedPathology(
                                index,
                                'label',
                                $event.target.value
                              )
                              " />
                            </b-col>
                            <b-col  cols="1"><input type="color" v-model="editedPathologies[index].color"
                              class="form-control-pathology-color" /></b-col>
                            <b-col cols="1"><span class="color-white tag-remove" @click="deletePathology(index)">
                              <b-icon class="color-white" icon="trash-fill"></b-icon>
                            </span></b-col>
                            <b-col  cols="2"><span>
                              <b-form-checkbox class="mb-1" v-model="primaryPath">Primary</b-form-checkbox>
                            </span></b-col>
                          </b-row>
                        </div>
                      </div>
                      <!-- pathology.primary ? '#aaa;' : '#ddd;'} -->
                      <b-row v-else class="color-white pathology-pill justify-content-between align-items-center mb-0 pb-0" :style="{borderColor: pathology.primary ? '#FFC848' :'#15385b'}"  align-h="between">
                        <b-col  cols="9"><div>{{ pathology.label }}</div></b-col>
                        <b-col cols="1"><div class="pathology-color" :style="{ backgroundColor: pathology.color }"></div></b-col>
                        <b-col cols="2" align-self="end"><button :disabled="editPathologies" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')"
                          class="btn btn-sm bg-yellow ml-2 rounded-pill mb-1" @click="setPathEdit(index)">
                          <b-icon class="color-black" icon="pencil-fill"></b-icon>
                        </button></b-col>
                      </b-row>
                      </div>
                    </div>
                  </div>
                  <div v-else class="no-data-message">
                    <b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon>
                    <p>
                      No pathologies added yet. Add some pathologies to get started.
                    </p>
                  </div>
                </div>

                <div v-if="editPathologies" class="py-2 card-footer footer-buttons">
                  <button class="btn btn-sm btn-secondary mr-1 rounded-pill" @click="cancelEditingPathologies">
                    Cancel
                  </button>
                  <button class="btn btn-sm bg-yellow rounded-pill color-black" @click="saveEditedPathologies">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Diagnoses" Active>
          <p class="my-2">
            Welcome to the Project Setup section. Customize your project's attributes
            with the following steps: tags, pathologies and diagnoses.
          </p>

          <div class="row">
            <div class="col-6">
              <div class="card border-cards"  :key="diaRkey">
                <div class="card-header bg-navy">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="color-white">
                      <b-icon icon="heart-fill"></b-icon>
                      Primary Diagnoses
                    </h6>
                    <div class="d-flex justify-content-between align-items-center" v-if="(current_role === 'Administrator' || current_role === 'Data Scientist')">
                      <button class="btn btn-sm bg-yellow ml-2 rounded-pill" @click="showDiagnosisModal(true)"
                        v-if="!editDiagnosis">
                        <b-icon class="color-black" icon="plus"></b-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body bg-dark-navy">
                  <!-- Display Diagnoses or Message -->
                  <div v-if="editDiagnosis || this.project.diagnosis.length > 0">
                    <div v-for="(diagnosis, index) in project.diagnosis" :key="index" class="mb-1">
                      <div v-if="diagnosis.primary">
                      <div v-if="diaEditing[index]">
                        <div class="tag-edit-container">
                          <b-row class="d-flex justify-content-between align-items-center pt-2">
                            <b-col cols="9"> <input class="form-control" :value="editedDiagnoses[index].label" @input="
                              updateEditedDiagnosis(
                                index,
                                'label',
                                $event.target.value
                              )
                              " /></b-col>
                            <b-col  cols="1"><span class="color-white diagnosis-remove" @click="deleteDiagnosis(index)">
                              <b-icon class="color-white" icon="trash-fill"></b-icon>
                            </span></b-col>
                            <b-col  cols="2"> <span>
                              <b-form-checkbox class="mb-1" v-model="primaryDia">Primary</b-form-checkbox>
                            </span></b-col>
                          </b-row>
                        </div>
                        </div>
                      <b-row v-else class="color-white diagnosis-pill mb-0 pb-0"  :style="{borderColor: diagnosis.primary ? '#FFC848' :'#15385b'}" align-h="between" >
                        <div>{{ diagnosis.label }}</div>
                          <button class="btn btn-sm bg-yellow ml-2 rounded-pill mb-1" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')" @click="setDiaEdit(index)" :disabled="editDiagnosis">
                            <b-icon class="color-black" icon="pencil-fill"></b-icon>
                          </button>
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div v-else class="no-data-message">
                    <b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon>
                    <p>No diagnoses added yet. Add some diagnoses to get started.</p>
                  </div>
                </div>

                <div v-if="editDiagnosis" class="py-2 card-footer footer-buttons">
                  <button class="btn btn-sm btn-secondary rounded-pill mr-1" @click="cancelEditingDiagnosis">
                    Cancel
                  </button>
                  <button class="btn btn-sm bg-yellow rounded-pill" @click="saveEditedDiagnosis">
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card border-cards"  :key="diaRkey">
                <div class="card-header bg-navy">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="color-white">
                      <b-icon icon="heart-fill"></b-icon>
                      Secondary Diagnoses
                    </h6>
                    <div class="d-flex justify-content-between align-items-center" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                      <button class="btn btn-sm bg-yellow ml-2 rounded-pill" @click="showDiagnosisModal(false)"
                        v-if="!editDiagnosis">
                        <b-icon class="color-black" icon="plus"></b-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body bg-dark-navy">
                  <!-- Display Diagnoses or Message -->
                  <div v-if="editDiagnosis || this.project.diagnosis.length > 0">
                    <div v-for="(diagnosis, index) in project.diagnosis" :key="index" class="mb-1">
                      <div v-if="!diagnosis.primary">
                      <div v-if="diaEditing[index]">
                        <div class="tag-edit-container">
                          <b-row class="d-flex justify-content-between align-items-center pt-2">
                            <b-col cols="9"> <input class="form-control" :value="editedDiagnoses[index].label" @input="
                              updateEditedDiagnosis(
                                index,
                                'label',
                                $event.target.value
                              )
                              " /></b-col>
                            <b-col  cols="1"><span class="color-white diagnosis-remove" @click="deleteDiagnosis(index)">
                              <b-icon class="color-white" icon="trash-fill"></b-icon>
                            </span></b-col>
                            <b-col  cols="2"> <span>
                              <b-form-checkbox class="mb-1" v-model="primaryDia">Primary</b-form-checkbox>
                            </span></b-col>
                          </b-row>
                        </div>
                        </div>
                      <b-row v-else class="color-white diagnosis-pill mb-0 pb-0"  :style="{borderColor: diagnosis.primary ? '#FFC848' :'#15385b'}" align-h="between" >
                        <div>{{ diagnosis.label }}</div>
                          <button v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')" class="btn btn-sm bg-yellow ml-2 rounded-pill mb-1" @click="setDiaEdit(index)" :disabled="editDiagnosis">
                            <b-icon class="color-black" icon="pencil-fill"></b-icon>
                          </button>
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div v-else class="no-data-message">
                    <b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon>
                    <p>No diagnoses added yet. Add some diagnoses to get started.</p>
                  </div>
                </div>

                <div v-if="editDiagnosis" class="py-2 card-footer footer-buttons">
                  <button class="btn btn-sm btn-secondary rounded-pill mr-1" @click="cancelEditingDiagnosis">
                    Cancel
                  </button>
                  <button class="btn btn-sm bg-yellow rounded-pill" @click="saveEditedDiagnosis">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Groups & Metadata">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-card no-body class="h-100 bg-dark-navy custom border-1" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                    <b-card-header class="px-3 title_staging">
                      Add Subgroup
                    </b-card-header>
                    <b-row align-h="center">
                      <div class="text-center my-4" v-if="gettingMeta">
                        <b-spinner scale="4" class="color-yellow"></b-spinner>
                        <div>Loading Metadata...</div>
                      </div>
                    </b-row>
                    <b-input-group v-for="(sub, index) in Object.keys(availableSubgroups)" :key=index
                      class="mx-2 mb-1 check_inputs">
                      <b-col align-self="start" cols="3">
                        <b-row class="mb-2 subgroup-input">
                          <b-checkbox class="mx-2" v-model="subgroupBase[sub].shown" @change="subgroupChanged++">{{ sub }}</b-checkbox>
                        </b-row>
                      </b-col>
                      <b-col align-self="center" cols="7">
                        <div class="mx-2" :key="subgroupChanged">
                          <div v-if="subgroupBase[sub].shown">
                            <b-col class=" targetBox">
                              <b-row class="mb-2" v-for="(option, index1) in availableSubgroups[sub]" :key=index1>
                                <b-checkbox class="mx-2" @change="cacheMeta(sub, option)"
                                  v-model="subgroupOptions[sub + option]"> {{ option
                                  }}</b-checkbox>
                              </b-row>
                            </b-col>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="1">
                        <b-button class="btn px-3 btn-sm btn-yellow rounded-pill" @click="openSubgroupModal(sub)">
                          <font-awesome-icon icon="plus" /> </b-button>
                      </b-col>
                    </b-input-group>
                    <b-row cols="12" align-h="center" class="mb-1 mt-2">
                          <b-input v-model="subGroupName" :state="subGroupName !== ''" placeholder="Add Subgroup Name" style="width: 50%;"></b-input>
                        </b-row>
                    <b-col class="mt-3">
                      <b-row align-h="around">
                        <b-button @click="resetSubgroupForm" pill class="btn-blue px-5">Reset</b-button>
                        <b-button @click="saveGroups" :disabled="subGroupName === ''" pill class="btn-yellow px-5">Add
                          Grouping</b-button>
                      </b-row>
                    </b-col>
                  </b-card>
                </b-col>

                <!-- subgroup Modal -->
                <b-modal centered v-model="addSubModal" header-close-content="<i class='fas fa-times color-white'></i>">
                  <template #modal-title>
                    <h4 class="mx-1 text-white">Add a new value to subgroup: {{ addSGTarget }}</h4>
                  </template>
                  <div>
                    <input v-model="addSGValue" placeholder="Enter subgroup value" @keyup.enter="addValuesSubgroupModal"
                      class="form-control" />
                    <div class="mt-3">
                      <b-row align-h="center">
                        <b-col v-for="(sg, index) in addSGValues" :key="index">
                          <span class="diagnosis-pill badge badge-primary mr-2">
                            {{ sg }}
                            <span class="diagnosis-remove" @click="removeModalSG(index)">x</span>
                          </span>
                        </b-col>
                      </b-row>
                    </div>
                    <p class="mt-1">
                      Hit Enter to add multiple Values, then click Save to update.
                    </p>
                  </div>
                  <div slot="modal-footer" class="d-flex justify-content-between">
                    <button class="btn rounded-pill bg-yellow mx-1" @click="addToSG">
                      Add
                    </button>
                    <button class="btn btn-secondary rounded-pill" @click="closeSGModal">
                      Cancel
                    </button>
                  </div>
                </b-modal>

                <b-col cols="12" :key="subgroupChanged" v-if="false">
                  <b-card no-body class="h-100 bg-dark-navy custom border-1">
                    <b-card-header class="px-3 title_staging">
                      Selected Subgroups
                    </b-card-header>
                    <div v-if="subgroupSelection.length > 0">
                      <div v-for="sg in subgroupSelection" :key="sg.name" class="subgroupFlag mt-1 mb-3">
                        <b-row class="mb-0" align-h="center">
                          <h6 class="color-white">{{ sg.name }} </h6>
                        </b-row>

                        <b-row class="mb-0" align-h="center">
                          <b-col class="groupflag" cols="5">
                            <p><strong class="color-white">Catagory</strong></p>
                          </b-col>
                          <b-col class="valueflag" cols="5">
                            <p><strong class="color-white">Values</strong></p>
                          </b-col>
                        </b-row>
                        <b-row v-for="(_sg, index) in Object.keys(sg.definiation)" :key="index" class="mb-0"
                          align-h="center">
                          <b-col class="groupflag" cols="5">
                            <p class="color-white">{{ _sg }}</p>
                          </b-col>
                          <b-col class="valueflag" cols="5">
                            <p class="color-white">{{ sg.definiation[_sg].join(', ') }}</p>
                          </b-col>
                          <!-- <div v-if="subgroupSelection.length > 0">
                      <div v-for="sg in subgroupSelection" :key="sg.name">
                        <div v-for="(_sg, index) in Object.keys(sg.definiation)" :key="index">
                          <p>{{ sg.name }} : {{ _sg }} : {{ sg.definiation[_sg] }}</p>
                        </div>
                      </div>
                    </div> -->
                        </b-row>
                      </div>
                      <b-col class="mt-3">
                        <b-row align-h="around">
                          <b-button @click="resetSubgroups" pill class="btn-blue px-5">Clear</b-button>
                          <b-button @click="saveGroups" pill class="btn-yellow px-5">Save</b-button>
                        </b-row>
                      </b-col>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row :key="subgroupChanged">
                <b-col>
                  <b-card no-body class="h-100 bg-dark-navy custom border-1">
                    <b-card-header class="px-3 title_staging">
                      Current Subgroups
                      <!-- <b-col>
                <b-button> Add </b-button>
                <b-card no-body class="h-100 bg-dark-navy custom border-1">
                  <b-card-header class="px-3 title_staging"
              </b-col> -->
                    </b-card-header>
                    <div v-if="project.subgroups && project.subgroups.length > 0" class="mt-1 px-3 currentSubgroups">
                      <div v-for="(sg) in project.subgroups" :key="sg.name" class="subgroupFlag mt-2 mb-3">
                        <b-row class="mt-3">
                          <b-col cols="11">
                            <h5 style="color: azure;"> {{ sg.name }} </h5>
                          </b-col>
                          <b-col cols="1">
                            <b-button class="btn btn-sm btn-yellow rounded-pill" @click="removeGroup(sg.name)">
                              <i class="fa fa-times"></i>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <b-row class="mb-0" align-h="center">
                              <b-col cols="3">
                                <p><strong class="color-white">Catagory</strong></p>
                              </b-col>
                              <b-col cols="9">
                                <p><strong class="color-white">Values</strong></p>
                              </b-col>
                            </b-row>
                            <b-row v-for="(_sg, index) in Object.keys(sg.definiation)" :key="index" class="mb-0"
                              align-h="center">
                              <b-col cols="3">
                                <p class="color-white">{{ _sg }}</p>
                              </b-col>
                              <b-col cols="9">
                                <p class="color-white">{{ sg.definiation[_sg].join(', ') }}</p>
                              </b-col>
                              <b-col></b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Additional tools">
          <b-card no-body class="h-100 bg-dark-navy custom border-1" v-if="(current_role === 'Administrator'|| current_role === 'Data Scientist')">
                    <b-card-header class="px-3 title_staging">
                      Tools
                    </b-card-header>
                    <b-col class='mt-2'>
                      <b-row align-h="around">
                        <b-col cols="5" class='PSAgroup'>
                          <b-row  class=" justify-content-center mt-1">
                            <b-form-checkbox class='' v-model="PSAEnabled" name="check-button" switch>
                          </b-form-checkbox>

                          Prostate-Specific Antigen Density
                          </b-row>
                        <b-row v-if="PSAEnabled">
                          <b-col >
                            <div v-for="(_path, index) in project.pathologies" :key="_path.label + index*0.356">
                              <b-row cols="11" class="mb-1 pb-1 ml-1 mr-1 pathology-pill  pb-0" align-h="around" :style="{borderColor: PSACheckedStates[_path.label] ? '#FFC848' :'#15385b'}"  >
                                <b-col cols="8" align-self="start">
                                  <p class="mb-0">{{_path.label}}</p>
                                </b-col>
                                <b-col cols="2" align-self="end">
                                  <b-form-checkbox class='' v-model="PSACheckedStates[_path.label]" name="check-button" switch>
                                  </b-form-checkbox>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="5" class="PSAgroup">
                          <b-row   class="justify-content-center mt-1">
                            Another tool
                          </b-row>
                        <b-row>
                        </b-row>
                        </b-col>
                      </b-row>
                      <b-row cols="12" class="justify-content-end">
                        <b-col align-self="end" cols="2" >
                          <button class="btn btn-sm bg-yellow rounded-pill color-black" @click="updateTools">
                            Save
                          </button>
                        </b-col>
                      </b-row>
                    </b-col>

          </b-card>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      PSAEnabled: false,
      PSACheckedStates: {},
      newTag: '',
      tags: [],
      newPathologyName: '',
      newPathologyColor: '#9DC183',
      pathologies: [],
      newDiagnosis: '',
      diagnoses: [],
      editMode: false,
      editPathologies: false,
      editDiagnosis: false,
      editedTags: [],
      editedPathologies: [...this.project.pathologies],
      editedDiagnoses: [...this.project.diagnosis],
      showingTagModal: false,
      newModalTag: '',
      modalTags: [],
      showingPathologyModal: false,
      newModalPathology: {
        label: '',
        color: '#9DC183'
      },
      modalPathologies: [],
      newModalDiagnosis: {
        label: ''
      },
      showingDiagnosisModal: false,
      modalDiagnoses: [],
      defaultColours: [
        '#B74400',
        '#A54200',
        '#933F00',
        '#813B00',
        '#6F3600',
        '#5D3100',
        '#4B2C00',
        '#3A2709',
        '#2A2213',
        '#1D1E1E',
        '#13192F',
        '#0C1441',
        '#0A1052',
        '#0B0C63',
        '#1E0A6E',
        '#3C0A6F',
        '#5C0F6B',
        '#7D1E5F',
        '#A4414A',
        '#C77D3D'
      ],
      colours: [
        '#B74400',
        '#A54200',
        '#933F00',
        '#813B00',
        '#6F3600',
        '#5D3100',
        '#4B2C00',
        '#3A2709',
        '#2A2213',
        '#1D1E1E',
        '#13192F',
        '#0C1441',
        '#0A1052',
        '#0B0C63',
        '#1E0A6E',
        '#3C0A6F',
        '#5C0F6B',
        '#7D1E5F',
        '#A4414A',
        '#C77D3D'
      ],
      subGroupName: '',
      availableSubgroups: {},
      subgroupSelection: [],
      subgroupBase: {},
      subgroupReset: {},
      subgroupChanged: 0,
      subgroupOptions: {},
      subgroupOptionsReset: {},
      subgroupCurrent: [],
      visibleImages: [],
      tabIndex: 0,
      gettingMeta: false,
      pathSuggestValues: [
        'absent ribs',
        'aneurysm',
        'atelectasis',
        'atherosclerotic plaques',
        'bone lesion',
        'bootshaped heart',
        'breast prosthesis',
        'bronchiectasis',
        'bullet fragments',
        'calcified mass',
        'calcified nodules',
        'cardiac lesion',
        'cardiomegaly',
        'cavity',
        'consolidation',
        'contrast within the bronchial tree',
        'cysts',
        'dextrocardia',
        'diaphragmatic hernia',
        'distended bowel',
        'eggshell calcifications',
        'elevated diaphragm',
        'emphysema',
        'fibrosis',
        'fluid in the fissure',
        'foreign bodies',
        'foreign body in the oesophagus',
        'ground glass',
        'hiatus hernia',
        'hyperinflation',
        'infiltrates',
        'irregular opacities',
        'lymphadenopathy',
        'mass',
        'micronodules',
        'mycetoma',
        'nasogastric tube',
        'nodules',
        'old ribfractures',
        'osteotomy',
        'overlying hardware',
        'pacemaker',
        'peribronchial thickening',
        'plate atelectasis',
        'pleural based lesion',
        'pleural effusion',
        'pleural plaques',
        'pleural thickening',
        'pleuroparenchymal bands',
        'pneumoperitoneum',
        'pneumothorax',
        'postsurgical hardware',
        'pulmonary arteries enlarged',
        'reticular infiltrates',
        'rib fractures',
        'scoliosis',
        'soft tissue calcifications',
        'surgical emphysema',
        'thickened fissure',
        'unfolded aorta',
        'vessels'
      ],
      addSubModal: false,
      addSGValues: [],
      addSGValue: '',
      addSGTarget: '',
      primaryPath: true,
      primaryDia: true,
      tagEditing: [],
      pathEditing: [],
      diaEditing: [],
      tagRkey: 0,
      pathRkey: 0,
      diaRkey: 0,
      enable_freehand: false
    }
  },
  computed: {
    ...mapState('projectSubmission', {
      projectImages: (state) => state.projectImages,
      projectimagesWatch: (state) => state.loadingProjectImages,
      projectLoading: (state) => state.loading,
      selectedProjectId: (state) => state.selectedProjectId
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('auth', ['user']),
    current_role () {
      const currentMem = this.members.find(p => p._id === this.user.id)
      // // console.log('member restrict', currentMem)
      if (currentMem) {
        // console.log('member restrict', currentMem, this.selectedProjectId)
        const currentPro = currentMem.projectRole.find(p => p.project === this.selectedProjectId)
        if (currentPro) {
          // console.log('member restrict', currentPro.role)
          return currentPro.role
        } else {
          // console.log('member restrict', currentPro)
          return 'Administrator'
        }
      } else {
        return 'View Only'
      }
    },
    imagesLoad () {
      return this.projectimagesWatch
    },
    loadProject () {
      return this.projectLoading
    },
    tagEditable () {
      return this.tagEditing.includes(true)
    }
  },
  watch: {
    loadProject (_new) {
      // console.log('project load:: pre', _new)
      if (!_new) {
        // console.log(this.project.pathologies.length)
        this.loadMetadataForProject()
        this.mapEditMode()
      }
    },
    imagesLoad (_new) {
      if (!_new) {
        const loadedImages = this.projectImages
        if (loadedImages) {
          this.visibleImages = [...this.visibleImages, ...loadedImages]
        } else {
          this.visibleImages = []
        }
      }
      this.loadMetadataForProject()
    }
  },
  methods: {
    ...mapActions('projectSubmission', {
      updateUserProject: 'updateProject',
      updateProjectInState: 'updateProjectInState'
    }),
    ...mapActions('projectSubmission', ['getProjectImages2', 'saveMetaData', 'getUserProjects']),
    compareLabel (a, b) {
      return b.primary - a.primary || a.label.localeCompare(b.label)
    },
    updateTools () {
      var _project = this.project
      // // // console.log(_project.subgroups)
      if (_project.tools == null) {
        _project.tools = {}
      }
      if (this.PSAEnabled) {
        if (_project.tools.psa == null) {
          _project.tools.psa = {}
        }
        _project.tools.psa = this.PSACheckedStates
      } else {
        _project.tools.psa = null
      }
      this.sortAndUpdateProject(_project)
      console.log('tools', this.PSACheckedStates)
    },
    saveFreehand () {
      var _project = this.project
      _project.freehand = this.enable_freehand
      this.updateUserProject(_project)
    },
    sortAndUpdateProject (project) {
      var _project = project
      _project.pathologies = project.pathologies.sort(this.compareLabel)
      _project.diagnosis = project.diagnosis.sort(this.compareLabel)
      this.updateUserProject(_project)
    },
    openSubgroupModal (sub) {
      this.addSubModal = true
      this.addSGTarget = sub
      // console.log(sub)
    },
    setTagEdit (i) {
      for (var j = 0; j < this.tagEditing.length; j++) {
        if (i === j) {
          this.tagEditing[j] = true
        } else {
          this.tagEditing[j] = false
        }
      }
      this.editMode = true
      this.tagRkey++
    },
    setPathEdit (i) {
      this.editedPathologies = this.project.pathologies.map((pathology) => ({
        label: pathology.label,
        value: pathology.value,
        color: pathology.color
      }))
      for (var j = 0; j < this.pathEditing.length; j++) {
        if (i === j) {
          this.pathEditing[j] = true
        } else {
          this.pathEditing[j] = false
        }
      }
      this.primaryPath = this.project.pathologies[i].primary
      this.editPathologies = true
      this.pathRkey++
    },
    setDiaEdit (i) {
      this.editedDiagnoses = this.project.diagnosis.map((diagnosis) => ({
        label: diagnosis.label
      }))
      for (var j = 0; j < this.diaEditing.length; j++) {
        if (i === j) {
          this.diaEditing[j] = true
        } else {
          this.diaEditing[j] = false
        }
      }
      this.primaryDia = this.project.diagnosis[i].primary
      this.editDiagnosis = true
      this.diaRkey++
    },
    mapEditMode () {
      this.tagEditing = []
      this.project.tags.forEach(t => {
        this.tagEditing.push(false)
      })
      this.pathEditing = []
      this.project.pathologies.forEach(t => {
        this.pathEditing.push(false)
      })
      this.diaEditing = []
      this.project.diagnosis.forEach(t => {
        this.diaEditing.push(false)
      })
    },
    addValuesSubgroupModal () {
      this.addSGValues.push(this.addSGValue)
      this.addSGValue = ''
    },
    removeModalSG (index) {
      this.addSGValues.splice(index, 1)
    },
    closeSGModal () {
      this.addSubModal = false
      this.addSGValues = []
      this.addSGValue = ''
    },
    addToSG () {
      // // console.log(this.subgroupBase[this.addSGTarget].selectedValues.concat(this.addSGValues))
      // // console.log(this.addSGValues.filter(v => !this.availableSubgroups[this.addSGTarget].includes(v)))
      var valuesToAdd = this.addSGValues.filter(v => !this.availableSubgroups[this.addSGTarget].includes(v))
      if (this.addSGValue && this.addSGValue !== '') {
        valuesToAdd = valuesToAdd.concat(this.addSGValue)
      }
      this.subgroupBase[this.addSGTarget].selectedValues = this.subgroupBase[this.addSGTarget].selectedValues.concat(valuesToAdd)
      this.availableSubgroups[this.addSGTarget] = this.availableSubgroups[this.addSGTarget].concat(valuesToAdd)
      valuesToAdd.forEach(val => {
        this.subgroupOptions[this.addSGTarget + val] = true
        this.subgroupOptionsReset[this.addSGTarget + val] = false
      })
      // add item to availsg
      this.addSubModal = false
      this.addSGValues = []
      this.addSGValue = ''
    },
    removeGroup (name) {
      // // console.log(name)
      var _project = JSON.parse(JSON.stringify(this.project))
      _project.subgroups = _project.subgroups.filter(sg => sg.name !== name)
      // // console.log(_project.subgroups)
      this.sortAndUpdateProject(_project)
      // this.resetSubgroups()
    },
    saveGroups () {
      this.addGroup()

      var _project = this.project
      // // // console.log(_project.subgroups)
      if (_project.subgroups == null) {
        _project.subgroups = []
      }
      for (let i = 0; i < this.subgroupSelection.length; i++) {
        const _subgroup = this.subgroupSelection[i]
        if (_subgroup !== undefined) {
          _project.subgroups.push(_subgroup)
        }
      }
      this.sortAndUpdateProject(_project)
      this.resetSubgroups()
    },
    addGroup () {
      var def = {}
      Object.keys(this.subgroupBase).forEach(sg => {
        if (this.subgroupBase[sg].selectedValues.length > 0) {
          def[sg] = this.subgroupBase[sg].selectedValues
        }
      })
      var payload = {
        name: this.subGroupName,
        definiation: def
      }
      this.subgroupSelection.push(payload)
      this.subgroupBase = JSON.parse(JSON.stringify(this.subgroupReset))
      this.subgroupOptions = JSON.parse(JSON.stringify(this.subgroupOptionsReset))
      this.subGroupName = ''
      this.subgroupChanged = 0
    },
    loadMetadataForProject () {
      console.log('project', this.project)
      if (this.project.tools && this.project.tools.psa) {
        this.PSAEnabled = true
        this.PSACheckedStates = this.project.tools.psa
      }
      this.enable_freehand = this.project.freehand ? this.project.freehand : false
      this.gettingMeta = true
      this.visibleImages.forEach(img => {
        img.metadata.forEach(md => {
          md.values.forEach(val => {
            if (Object.keys(this.availableSubgroups).includes(val.name)) {
              if (!this.availableSubgroups[val.name].includes(val.value)) {
                this.availableSubgroups[val.name].push(val.value)
              }
            } else {
              this.availableSubgroups[val.name] = [val.value]
            }
          })
          // // // console.log(this.availableSubgroups)
        })
      })
      this.availableSubgroups.Pathologies = this.project.pathologies.map(_p => _p.label)
      this.availableSubgroups.Diagnosis = this.project.diagnosis.map(_p => _p.label)
      this.availableSubgroups.Tags = this.project.tags

      // availableSubgroups: { gender: ['Female', 'Male'], age: [1, 2, 3, 4, 5, 6], view: ['AP', 'PA'] },
      var _tmp = Object.keys(this.availableSubgroups)
      _tmp.forEach(sg => {
        this.subgroupBase[sg] = {
          property: sg,
          values: this.availableSubgroups[sg].sort(),
          shown: false,
          selectedValues: []
        }
        this.availableSubgroups[sg].forEach(opt => {
          this.subgroupOptions[sg + opt] = false
        })
      })
      this.subgroupOptionsReset = JSON.parse(JSON.stringify(this.subgroupOptions))
      this.subgroupReset = JSON.parse(JSON.stringify(this.subgroupBase))
      this.gettingMeta = false
    },
    resetSubgroupForm () {
      this.subgroupBase = JSON.parse(JSON.stringify(this.subgroupReset))
      this.subgroupOptions = JSON.parse(JSON.stringify(this.subgroupOptionsReset))
      this.subGroupName = ''
      this.subgroupChanged = 0
    },
    resetSubgroups () {
      this.subgroupSelection = []
    },
    cacheMeta (subgroup, option) {
      if (this.subgroupBase[subgroup].selectedValues.includes(option)) {
        this.subgroupBase[subgroup].selectedValues = this.subgroupBase[subgroup].selectedValues.filter(val => val !== option)
      } else {
        this.subgroupBase[subgroup].selectedValues.push(option)
      }
    },
    addDemo () {
      this.demographics.push({ subgroup: this.demoSubgroup, value: this.demoValue })
      this.demoSubgroup = ''
      this.demoValue = ''
    },
    addTag () {
      if (this.newTag.trim() !== '') {
        this.tags.push(this.newTag.trim())
        this.newTag = ''
      }
    },
    removeTag (index) {
      this.tags.splice(index, 1)
    },
    async deleteTag (index) {
      this.project.tags.splice(index, 1)
      await this.sortAndUpdateProject({ ...this.project })
    },
    editTags () {
      this.editTags = true
      this.editedTags = [...this.tags]
    },
    toggleEditTags () {
      this.editMode = !this.editMode

      if (this.editMode) {
        this.editedTags = [...this.tags]
      } else {
        this.editedTags = []
      }
    },
    cancelEditingTags () {
      for (var i = 0; i < this.tagEditing.length; i++) {
        this.tagEditing[i] = false
      }
      this.editMode = false
      this.editedTags = []
      this.tagRkey++
    },
    updateEditedTag (index, value) {
      this.editedTags[index] = value
    },

    saveEditedTags () {
      for (let i = 0; i < this.editedTags.length; i++) {
        const editedTag = this.editedTags[i]
        if (editedTag !== undefined && editedTag !== '') {
          this.project.tags[i] = editedTag
        }
      }

      for (var i = 0; i < this.tagEditing.length; i++) {
        this.tagEditing[i] = false
      }
      this.editMode = false
      this.editedTags = []
      this.sortAndUpdateProject(this.project)
      this.tagRkey++
    },

    addPathology () {
      if (this.newPathologyName.trim() !== '') {
        this.pathologies.push({
          label: this.newPathologyName.trim(),
          value: this.newPathologyName.trim(),
          color: this.newPathologyColor
        })
        this.newPathologyName = ''
        this.newPathologyColor = '#9DC183'
      }
    },

    removePathology (index) {
      this.pathologies.splice(index, 1)
    },
    addDiagnosis () {
      if (this.newDiagnosis.trim() !== '') {
        this.diagnoses.push({
          value: this.newDiagnosis.trim(),
          label: this.newDiagnosis.trim()
        })
        this.newDiagnosis = ''
      }
    },
    removeDiagnosis (index) {
      this.diagnoses.splice(index, 1)
    },
    setColor (event) {
      this.newPathologyColor = event.target.value.trim()
    },
    async submitData () {
      const tagsToAdd = this.tags.filter(
        (tag) => !this.project.tags.includes(tag)
      )
      const pathologiesToAdd = this.pathologies.filter(
        (pathology) =>
          !this.project.pathologies.some((p) => p.label === pathology.label)
      )
      const diagnosesToAdd = this.diagnoses.filter(
        (diagnosis) =>
          !this.project.diagnosis.some((d) => d.label === diagnosis.label)
      )

      const updatedProject = {
        ...this.project,
        tags: [...this.project.tags, ...tagsToAdd],
        pathologies: [...this.project.pathologies, ...pathologiesToAdd],
        diagnosis: [...this.project.diagnosis, ...diagnosesToAdd]
      }
      await this.sortAndUpdateProject(updatedProject)
      this.updateProjectInState(updatedProject)
      this.clearData()
    },
    toggleEditPathologies () {
      this.editPathologies = !this.editPathologies
      // // // console.log(this.editedPathologies)
      if (this.editPathologies) {
        this.editedPathologies = this.project.pathologies.map((pathology) => ({
          label: pathology.label,
          value: pathology.value,
          color: pathology.color
        }))
      } else {
        this.editedPathologies = []
      }
    },
    cancelEditingPathologies () {
      for (var i = 0; i < this.pathEditing.length; i++) {
        this.pathEditing[i] = false
      }
      this.editPathologies = false
      this.pathRkey++
    },
    updateEditedPathology (index, field, value) {
      this.editedPathologies[index][field] = value
      if (field === 'color') {
        const pathologyElement = this.$refs.pathologyPills[index]
        if (pathologyElement) {
          pathologyElement.style.backgroundColor = value
        }
      }
    },

    saveEditedPathologies () {
      for (let i = 0; i < this.editedPathologies.length; i++) {
        const editedPathology = this.editedPathologies[i]
        if (
          editedPathology.label !== undefined &&
          editedPathology.label !== ''
        ) {
          this.project.pathologies[i].label = editedPathology.label
          this.project.pathologies[i].value = editedPathology.label
          this.project.pathologies[i].color = editedPathology.color
        }
      }
      for (var i = 0; i < this.pathEditing.length; i++) {
        if (this.pathEditing[i]) {
          this.project.pathologies[i].primary = this.primaryPath
        }
        this.pathEditing[i] = false
      }
      this.editPathologies = false
      this.sortAndUpdateProject(this.project)
      this.pathRkey++
    },
    deletePathology (index) {
      for (var i = 0; i < this.pathEditing.length; i++) {
        this.pathEditing[i] = false
      }
      this.project.pathologies.splice(index, 1)
      this.sortAndUpdateProject({ ...this.project })
      this.editedPathologies = this.project.pathologies.map((pathology) => ({
        label: pathology.label,
        value: pathology.value,
        color: pathology.color
      }))
      this.editPathologies = false
    },
    updateEditedDiagnosis (index, field, value) {
      this.editedDiagnoses[index][field] = value
    },
    toggleEditDiagnosis () {
      this.editDiagnosis = !this.editDiagnosis

      if (this.editDiagnosis) {
        this.editedDiagnoses = this.project.diagnosis.map((diagnosis) => ({
          label: diagnosis.label
        }))
      } else {
        this.editedDiagnoses = []
      }
    },
    saveEditedDiagnosis () {
      for (let i = 0; i < this.editedDiagnoses.length; i++) {
        const editedDiagnosis = this.editedDiagnoses[i]
        // // // console.log('Edited Diagnosis:', editedDiagnosis)

        if (
          editedDiagnosis.label !== undefined &&
          editedDiagnosis.label !== ''
        ) {
          this.project.diagnosis[i].label = editedDiagnosis.label
        }
      }
      for (var i = 0; i < this.diaEditing.length; i++) {
        if (this.diaEditing[i]) {
          this.project.diagnosis[i].primary = this.primaryDia
        }
        this.diaEditing[i] = false
      }

      this.editDiagnosis = false
      this.sortAndUpdateProject(this.project)
    },
    deleteDiagnosis (index) {
      for (var i = 0; i < this.diaEditing.length; i++) {
        this.diaEditing[i] = false
      }
      this.project.diagnosis.splice(index, 1)
      this.sortAndUpdateProject({ ...this.project })
      this.editedDiagnoses = this.project.diagnosis.map((pathology) => ({
        label: pathology.label
      }))
      this.editDiagnosis = false
    },
    cancelEditingDiagnosis () {
      for (var i = 0; i < this.diaEditing.length; i++) {
        this.diaEditing[i] = false
      }
      this.editDiagnosis = false
      this.diaRkey++
    },
    showTagModal () {
      this.showingTagModal = true
    },

    closeTagModal () {
      this.showingTagModal = false
      this.newModalTag = ''
    },

    addModalTag () {
      if (this.newModalTag.trim() !== '') {
        this.modalTags.push(this.newModalTag.trim())
        this.newModalTag = ''
      }
    },

    removeModalTag (index) {
      this.modalTags.splice(index, 1)
    },

    // Save tags from the modal and update the main tags list
    async saveModalTags () {
      if (this.newModalTag && this.newModalTag.trim() !== '') {
        this.addModalTag()
      }

      if (this.modalTags.length > 0) {
        const tagsToAdd = this.modalTags.filter(
          (tag) => !this.project.tags.includes(tag)
        )
        const updatedProject = {
          ...this.project,
          tags: [...tagsToAdd, ...this.project.tags]
        }
        await this.sortAndUpdateProject(updatedProject)
        this.updateProjectInState(updatedProject)
        this.modalTags = []
        this.closeTagModal()
      }
    },
    showPathologyModal (primary) {
      this.primaryPath = primary
      this.showingPathologyModal = true
    },
    closePathologyModal () {
      this.showingPathologyModal = false
      this.newModalPathology = {
        label: '',
        color: '#9DC183'
      }
    },
    addModalPathology () {
      if (this.newModalPathology.label.trim() !== '') {
        if (this.defaultColours.length === 0) {
          this.defaultColours = this.colours
        }
        var color = this.defaultColours.pop()
        var flag = true
        while (flag) {
          if (this.project.pathologies.find(p => p.color === color)) {
            color = this.defaultColours.pop()
          } else {
            flag = false
          }
        }
        this.modalPathologies.push({
          label: this.newModalPathology.label.trim(),
          color: color,
          primary: this.primaryPath
        })
        this.newModalPathology.label = ''
      }
    },

    removeModalPathology (index) {
      this.modalPathologies.splice(index, 1)
    },

    async saveModalPathologies () {
      if (this.newModalPathology.label && this.newModalPathology.label.trim() !== '') {
        this.addModalPathology()
      }

      if (this.modalPathologies.length > 0) {
        const pathologiesToAdd = this.modalPathologies.filter(
          (pathology) =>
            !this.project.pathologies.some((p) => p.label === pathology.label)
        )
        const updatedProject = {
          ...this.project,
          pathologies: [...pathologiesToAdd, ...this.project.pathologies]
        }
        await this.sortAndUpdateProject(updatedProject)
        this.updateProjectInState(updatedProject)
        this.modalPathologies = []
        this.showingPathologyModal = false
      }
    },
    // Diagnosis Modal
    showDiagnosisModal (primary) {
      this.primaryDia = primary
      this.showingDiagnosisModal = true
    },
    closeDiagnosisModal () {
      this.showingDiagnosisModal = false
      this.newModalDiagnosis.label = ''
    },
    addModalDiagnosis () {
      if (this.newModalDiagnosis.label.trim() !== '') {
        this.modalDiagnoses.push({
          label: this.newModalDiagnosis.label.trim(),
          primary: this.primaryDia
        })
        this.newModalDiagnosis.label = ''
      }
    },

    removeModalDiagnosis (index) {
      this.modalDiagnoses.splice(index, 1)
    },

    async saveModalDiagnoses () {
      if (this.newModalDiagnosis.label && this.newModalDiagnosis.label.trim() !== '') {
        this.addModalDiagnosis()
      }

      if (this.modalDiagnoses.length > 0) {
        const diagnosesToAdd = this.modalDiagnoses.filter(
          (diagnosis) =>
            !this.project.diagnosis.some((d) => d.label === diagnosis.label)
        )
        const updatedProject = {
          ...this.project,
          diagnosis: [...diagnosesToAdd, ...this.project.diagnosis]
        }
        await this.sortAndUpdateProject(updatedProject)
        this.updateProjectInState(updatedProject)
        this.modalDiagnoses = []
        this.closeDiagnosisModal()
      }
    }
  },
  mounted () {
    this.getUserProjects()
    this.gettingMeta = true
    this.visibleImages = this.projectImages
    this.loadMetadataForProject()
    this.mapEditMode()
  }
  // beforeMount () {
  //   this.getProjectImages2(this.project._id)
  // }
}
</script>

<style>
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 1rem;
}

p {
  font-size: 14px;
  margin-bottom: 10px;
}

.color-white {
  color: #fff;
}

.currentSubgroups {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(70vh);
}

.subgroup-input {
  /* border: 1px solid #ccc;
  border-radius: 15px; */
}

.targetBox {
  max-height: 15vh;
  overflow-y: scroll;
  /* border: 1px solid #cccccc72;
  border-radius: 5px; */
}

.check_inputs {
  margin-bottom: 3px;
  border-bottom: 1px solid #1d4b70;
}

.subgroup-selection {
  border: 1px solid #cccccc81;
}

.subgroupFlag:not(:first-of-type) {
  border-top: 1px solid #1d4b70;
}

.groupflag {
  border-top: 1px solid #cccccc2d;
}

.valueflag {
  border-top: 1px solid #cccccc2d;
  /* border-left: 1px solid #cccccc81; */
}

.tag-inbox input,
.pathology-inbox input,
.diagnosis-inbox input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  font-size: 14px;
}

.tag-pills,
.pathology-pills,
.diagnosis-pills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag-pill,
.pathology-pill,
.diagnosis-pill {
  display: flex;
  align-items: center;
  border-color: #ccc;
  color: #fff;
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: 500;
}

.PSAgroup {
  align-items: center;
  border-color: #ccc;
  color: #fff;
  border: 1px solid #1d4b70;
  border-radius: 10px;
  font-weight: 500;
}

.tag-remove,
.pathology-remove,
.diagnosis-remove {
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
}

.pathology-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

.form-control-pathology {
  width: 40px;
  height: 40px;
  padding: 0;
}

.submit-button {
  margin-top: 20px;
}

.current-data-container {
  display: flex;
  overflow-x: y;
  gap: 20px;
  padding-top: 10px;
  margin-top: 10px;
}

.current-data-column {
  flex: 1;
  max-width: 100%;
  padding: 10px;
  border-radius: 8px;
  overflow-y: auto;
  position: relative;
}

.form-control-pathology-color {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  appearance: none;
  cursor: pointer;
}
</style>
