<template>
  <b-list-group flush :key="rerender">
    <b-list-group-item :href="'#phase-' + phase.id" @click.prevent="selectPhase(phase.id)"
      v-for="(phase, $index) in phases[studyType]" :key="$index" :active="phase.id === active">
      <b-row v-if="phase.id !== 2" class="my-0">
        <b-col cols="2" class="pr-0">
          <font-awesome-icon :icon="phase.icon" size="2x" class="text-white" />
        </b-col>
        <b-col class="d-flex flex-column">
          <b-row class="mb-1">
            <b-col cols="11" class="pl-0">
              <h6 class="color-white pt-1 mb-0 d-inline-block text-truncate" style="width: calc(100% - 0.25rem);">
                <span v-if="phase.id === 5">{{ projectType.charAt(0).toUpperCase() + projectType.substring(1) }}
                  set-up</span>
                <span v-else>{{ phase.name }}</span>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400"
                  v-if="[1, 3, 4, 6, 7, 9, 10, 11].includes(phase.id)">
                  <span v-if="1 === phase.id && !projectimagesWatch">{{ (projectImages ? projectImages.length : 0) }}</span>
                  <span v-if="1 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="6 === phase.id && !aiImagesWatch">{{ processed ? processed :0  }}</span>
                  <span v-if="6 === phase.id && aiImagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="7 === phase.id  && !aiImagesWatch && !statsLoading">{{ processed ? processed - failed :0  }}</span>
                  <span v-if="7 === phase.id && (aiImagesWatch || statsLoading)" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="3 === phase.id && !imagesWatch">{{ fully_annotated.length + partially_annotated.length  }} </span>
                  <span v-if="3 === phase.id && imagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="4 === phase.id  && !imagesWatch">{{ fully_annotated.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0) }} </span>
                  <span v-if="4 === phase.id && imagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="9 === phase.id && !loading">{{ members.length }} </span>
                  <span v-if="9 === phase.id && loading" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                </b-badge>

              </h6>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 1">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Data source: <strong class="text-white">{{ list[0].source.charAt(0).toUpperCase() +
                    list[0].source.slice(1) }}</strong><br />
                  Data location: <strong class="text-white">{{ projectType === 'project' ? (list.length > 0 ? 'On-premise'
                    : 'Unknown') : 'Cloud' }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Received: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) }}</strong><br />
                  Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                  <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                    :max="list.length === 163 ? 174 : list.length" :value="list.length" />
                </small></p>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-1" v-if="phase.id === 2">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  De-identification: <strong class="text-white">No</strong><br />
                  Image Filters: <strong class="text-white">Chest AP / PA</strong><br />
                  Image Correction: <strong class="text-white">Greyscale</strong><br />
                  Scale, Straighten &amp; Crop: <strong class="text-white">Yes, 1024x1024</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Accepted: <strong class="text-white">{{ list.length === 163 ? 174 : list.length }}</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                :max="list.length === 163 ? 174 : list.length" :value="list.length" />
            </b-col>
          </b-row> -->
          <b-row class="mb-1" v-if="phase.id === 3">
            <b-col cols="5">
              <p class="mb-0 line-height-1">
                <small>
                  Inter-observer Variability: <strong class="text-white">{{ project_IOV >= 0 ? (project_IOV * 100).toFixed(2) +'%' : 'Not Enough Data'}}</strong><br />
                  Fully Annotated: <strong class="text-white">{{ fully_annotated.length }}</strong><br />
                  Partially Annotated: <strong class="text-white">{{ partially_annotated.length }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1">
                <small>
                  Not Annotated: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) - fully_annotated.length -partially_annotated.length }}</strong><br />
                  Moderated: <strong class="text-white">{{ moderatedCount.length }}
                  ({{  moderatedCount.length === 0 ? 0: Math.round(moderatedCount.length / (moderatedCount.length + fully_annotated.length + partially_annotated.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0)) * 10000) / 100 }}%)</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="projectImages ? projectImages.length : 0" :value="full_annotated + partial_annotated" />
            </b-col>
          </b-row>
          <!-- && list.length === 200" -->
          <b-row class="mb-1" v-if="phase.id === 4">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Full Agreement: <strong class="text-white">{{ fully_annotated.length - partialAgreement.length - fullDisagreement.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0) }}</strong><br />
                Partial Disagreement: <strong class="text-white">{{ partialAgreement.length }}</strong><br />
                Full Disagreement: <strong class="text-white">{{ fullDisagreement.length}}</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Moderated: <strong class="text-white">{{ moderatedCount.length }}
                  ({{ moderatedCount.length === 0 ? 0:   Math.round(moderatedCount.length / (moderatedCount.length + fully_annotated.length - moderatedCount.length - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0)) * 10000) / 100 }}%)</strong><br />
                Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
              </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="partial_disagreements + full_disagreements" :value="moderated" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 12 && list[0].enable_training">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Training configuration saved: <strong class="text-white">True</strong><br />
                Pathologies Selected: <strong class="text-white">0</strong><br />
                Diagnoses Selected: <strong class="text-white">0</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Training status: <strong class="text-white"> Pending</strong><br />
                Training stats: <strong class="text-white"> Pending</strong><br />
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 6">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Available: <strong class="text-white">{{ fully_annotated.length - partialAgreement.length - fullDisagreement.length - processed + failed + moderatedCount.length}}</strong><br />
                  Submitted <strong class="text-white">{{processed}}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Processed: <strong class="text-white">{{processed - failed}}</strong><br />
                  Failed: <strong class="text-white">{{ failed }}</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                :max="list.length === 163 ? 174 : list.length" :value="list.length" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 7">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  TP: <strong class="text-white">{{ confusionMatrix.truePositive }} {{ '('+ (confusionMatrix.truePositive === 0 ? 0 : (confusionMatrix.truePositive / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  FP: <strong class="text-white">{{ confusionMatrix.falsePositive }}  {{ '('+ (confusionMatrix.falsePositive === 0 ? 0 :  (confusionMatrix.falsePositive  / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" >
              <p class="mb-0 line-height-1"><small>
                  FN: <strong class="text-white">{{ confusionMatrix.falseNegative}}  {{ '('+ (confusionMatrix.falseNegative === 0 ? 0 : (confusionMatrix.falseNegative / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  TN: <strong class="text-white">{{ confusionMatrix.trueNegative}}  {{ '('+ (confusionMatrix.trueNegative === 0 ? 0 : (confusionMatrix.trueNegative / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 8">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Sensitivity: <strong class="text-white">{{ ((confusionMatrix.truePositive + confusionMatrix.falseNegative) === 0 ? 0 : confusionMatrix.truePositive / (confusionMatrix.truePositive + confusionMatrix.falseNegative) *100).toFixed(2)+ '%' }}</strong><br />
                  Specificity: <strong class="text-white">{{ ((confusionMatrix.trueNegative + confusionMatrix.falsePositive) === 0 ? 0 : confusionMatrix.trueNegative / (confusionMatrix.trueNegative + confusionMatrix.falsePositive) *100).toFixed(2)+ '%' }}</strong><br />
                  Area under Curve: <strong class="text-white">{{ auc.toFixed(4) }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  PPV: <strong class="text-white">{{ (confusionMatrix.truePositive + confusionMatrix.falsePositive) !== 0 ? (confusionMatrix.truePositive / (confusionMatrix.truePositive + confusionMatrix.falsePositive)).toFixed(4) : 0}}</strong><br />
                  NPV: <strong class="text-white">{{  (confusionMatrix.falseNegative + confusionMatrix.trueNegative) !== 0 ? (confusionMatrix.trueNegative / (confusionMatrix.falseNegative + confusionMatrix.trueNegative)).toFixed(4) : 0 }}</strong><br />
                  F1: <strong class="text-white"> {{ (((2*confusionMatrix.truePositive) + confusionMatrix.falsePositive + confusionMatrix.falseNegative))!== 0 ?  ((2*(confusionMatrix.truePositive) / (((2*confusionMatrix.truePositive) + confusionMatrix.falsePositive + confusionMatrix.falseNegative))) *100).toFixed(2)+ '%' : 0}}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 11">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Concordance: <strong class="text-white">155 ({{ Math.round(155 / 163 * 10000) / 100 }}%)</strong><br />
                  Deviation: <strong class="text-white">1 ({{ Math.round(getStandardDeviation([155, 154]) * 100) / 100
                  }}%)</strong><br />
                </small></p>
            </b-col>reloadList
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Discordance: <strong class="text-white">8 ({{ Math.round(8 / 163 * 10000) / 100 }}%)</strong><br />
                </small></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { IOVThesholds } from '../../../helpers/iovthresholds'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../helpers'

export default {
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    active: {
      type: Number,
      default: null
    },
    studyType: {
      type: String,
      default: 'retrospective'
    },
    projectType: {
      type: String,
      default: 'project'
    }
  },
  data () {
    return {
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'Project Details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          // { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 12, stub: 'training', name: 'AI Model Training', icon: 'users-cog' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 10, stub: 'feedback', name: 'Inference feedback', icon: 'crop' },
          { id: 11, stub: 'moderation', name: 'Feedback moderation', icon: 'check-double' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      rejected: {},
      annotated_images: [],
      projectIOV: 0,
      full_disagreements: 0,
      partial_disagreements: 0,
      full_annotated: 0,
      partial_annotated: 0,
      not_annotated: 0,
      moderated: 0,
      unmoderated: 0,
      totalProjectImages: 0,
      memCount: 0,
      rejections: [],
      rerender: 0
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state.labelledImages,
      imagesWatch: (state) => state.loading,
      aiImages: (state) => state.aiImages,
      aiImagesWatch: (state) => state.aiLoading
    }),
    ...mapState('projectSubmission', {
      projectImages: (state) => state.projectImages,
      projectimagesWatch: (state) => state.loadingProjectImages,
      rejects: (state) => state.rejects,
      loadingRejects: (state) => state.loadingRejects,
      curProject: (state) => state.selectedProjectId,
      stats: (state) => state.stats,
      statsLoading: (state) => state.statsLoading
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    projectId () {
      if (this.members && this.members.length > 0 && this.list && this.list[0]) {
        return this.list[0]._id
      } else {
        return null
      }
    },
    readers () {
      if (this.members && this.members.length > 0 && this.list && this.list[0]) {
        const _project = this.list[0]._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.length > 0 && this.list[0]) {
        const _project = this.list[0]._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    imagesLoad () {
      return this.projectimagesWatch
    },
    rejectLoad () {
      return this.loadingRejects
    },
    labelledLoad () {
      return this.imagesWatch
    },
    aiLoad () {
      return this.aiImagesWatch
    },
    aiStatsLoad () {
      return this.statsLoading
    },
    reloadList () {
      return this.curProject
    },
    fully_annotated () {
      var temp = []
      if (this.readers.length) {
        // // console.log('fully', this.images.filter(img => img.users.length === 1))
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.users.length >= this.readers.length) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    partially_annotated () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.users.length < this.readers.length && img.users.length > 0) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    partialAgreement () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
              var acc = img.accuracy
              if (acc > IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                temp.push(acc)
              }
            }
          }
        })
      }
      return temp
    },
    fullDisagreement () {
      var temp = []
      if (this.readers.length) {
        // // console.log('fa:', this.images.map(x => x.accuracy))
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length && !(this.readers.length === 1 && img.users.length === 1)) {
              var acc = img.accuracy
              if (acc > IOVThesholds().partialAgreement) {
                temp.push(acc)
              }
              // }
            }
          }
        })
      }
      return temp
    },
    project_IOV () {
      var temp = []
      var sum = 0
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.users.length >= this.readers.length) {
              var acc = img.accuracy
              sum += acc
              temp.push(acc)
            }
          }
        })
      }
      return sum / temp.length
    },
    moderatedCount () {
      var temp = []
      if (this.readers.length) {
        // // console.log('mod', this.images)
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            var modFlag = img.users.filter(u => u.role === 'Moderator').length > 0
            if (modFlag) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    processed () {
      if (this.aiImages && this.aiImages.length > 0) {
        return this.aiImages.filter(img => (!img.removed.find(p => p.project === this.list[0]._id))).length
      } else {
        return 0
      }
    },
    failed () {
      if (this.aiImages && this.aiImages.length > 0) {
        return this.aiImages.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)) && (img.diagnosis && (img.diagnosis === 'Error' || img.diagnosis === 'Unknown'))).length
      } else {
        return 0
      }
    },
    confusionMatrix () {
      const _matrix = {
        truePositive: 0,
        falsePositive: 0,
        falseNegative: 0,
        trueNegative: 0
      }
      const projectStats = this.stats.find(p => p.projectId === this.list[0]._id)
      if (projectStats && projectStats.stats && projectStats.stats.length > 0) {
        const metrics = projectStats.stats.find(p => p.subgroup === 'All')
        if (metrics && metrics.metrics.length > 0) {
          const targetMetric = metrics.metrics.find(m => m.threshold === projectStats.confiThes)
          // console.log('stats3.5', targetMetric)
          if (targetMetric) {
            _matrix.truePositive = targetMetric.TP
            _matrix.falsePositive = targetMetric.FP
            _matrix.falseNegative = targetMetric.FN
            _matrix.trueNegative = targetMetric.TN
          }
          // console.log('stats4', targetMetric)
        }
      }
      return _matrix
    },
    metrics () {
      const projectStats = this.stats.find(p => p.projectId === this.list[0]._id)
      if (projectStats && projectStats.stats && projectStats.stats.length > 0) {
        const metrics = projectStats.stats.find(p => p.subgroup === 'All')
        if (metrics && metrics.metrics.length > 0) {
          return metrics.metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.003175) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    confusedSum () {
      return this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive + this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative
    }
  },
  watch: {
    aiLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // var projectImages = this.projectImages
        // this.totalProjectImages = projectImages ? projectImages.length : 0
        this.rerender++
      }
    },
    aiStatsLoad (_new) {
      // console.log('stats', _new)
      if (!_new && this.curProject === this.list[0]._id) {
        this.rerender++
      }
    },
    imagesLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // // console.log('pull img', _new)
        var projectImages = this.projectImages
        this.totalProjectImages = projectImages ? projectImages.length : 0
        this.rerender++
      }
    },
    rejectLoad (_new) {
      if (!_new && this.curProject === this.list[0]._id) {
        // // // console.log(this.rejects)
        this.rejections = this.rejects
        this.rerender++
      }
    },
    labelledLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // // console.log('images break', this.images)
        if (this.images && this.images.lngth > 0) {
          this.annotated_images = this.images.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)))
        }
        this.rerender++
      }
    },
    reloadList (_new) {
      if (this.list[0]._id === _new) {
        if (!this.list[0].enable_training) {
          this.phases.retrospective = this.phases.retrospective.filter(ph => ph.id !== 12)
        }
        this.getMembers(this.list[0]._id)
        this.fetchLabelledImages2(this.list[0]._id)
        // // console.log('pul')
        this.fetchAILabelledImages(this.list[0]._id)
        this.getProjectImages2(this.list[0]._id)
        this.getRejections2(this.list[0]._id)
        this.getAllImages2(this.list[0]._id)
        this.getProjectStats(this.list[0]._id)
        this.getProjectDetailedStats(this.list[0]._id)
        this.rerender++
      }
    }

  },
  methods: {
    selectPhase: function (id) {
      this.$emit('phase-selected', id)
    },
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'getAllImages2', 'getProjectStats', 'getProjectDetailedStats']),
    formatDate: function (date) {
      return moment(date).fromNow()
    },
    formatFullDate: function (date) {
      return moment(date).format('D MMM YYYY, HH:mm')
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return ''
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return ''
      } else if (name.indexOf('other') > -1) {
        return ''
      } else {
        return ''
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'circle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'circle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'high'
      } else {
        return 'nothing'
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    getStandardDeviation: function (array) {
      const n = array.length
      const mean = array.reduce((a, b) => a + b) / n
      return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    }
  },
  mounted () {
    if (this.list[0] && this.curProject === this.list[0]._id) {
      if (!this.list[0].enable_training) {
        this.phases.retrospective = this.phases.retrospective.filter(ph => ph.id !== 12)
      }
      this.getMembers(this.list[0]._id)
      this.fetchLabelledImages2(this.list[0]._id)
      this.getProjectImages2(this.list[0]._id)
      // // console.log('pul')
      this.fetchAILabelledImages(this.list[0]._id)
      this.getRejections2(this.list[0]._id)
      this.getAllImages2(this.list[0]._id)
      this.getProjectStats(this.list[0]._id)
      this.getProjectDetailedStats(this.list[0]._id)
    }
  }
}
</script>
