<template>
  <div class="all-images">
    <div class="container text-center">
      <b-row>
        <b-col cols="12">
            <b-row align-h="center" class="pt-1">
        <b-input-group class="rounded-pill navy-fields">
          <template #prepend>
            <b-input-group-text
              class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control"
            >
              <font-awesome-icon icon="search" />
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text
              class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control"
            >
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="search"
            placeholder="Search Image Name"
            style="width: 25rem"
          ></b-form-input>
          <b-dropdown  class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" :text="filterChoice === '' ?  'All':filterChoice " variant="warn">
            <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
            <b-dropdown-item v-for="sg in projectId.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
          </b-dropdown>
        </b-input-group>
      </b-row>
          <b-row v-if="(current_role === 'Administrator' || current_role === 'Data Loader' || current_role === 'Data Scientist')">
            <b-col>
              <b-button v-if="selectedImageCount > 0 || singleAssign" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="assignToProject" :disabled="isLoading">
                <span v-if="isLoading">
                  <i class="fa fa-spinner fa-spin"></i> Loading...
                </span>
                <span v-else> Assign {{ selectedImageCount }} Images to Project </span>
              </b-button>
              <p v-else>No Images Selected</p>
            </b-col>
            <b-col>
              <b-button v-if="sortflag" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleSort()"><font-awesome-icon icon="sort-numeric-up"></font-awesome-icon></b-button>
              <b-button v-if="!sortflag" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleSort()"><font-awesome-icon icon="sort-numeric-down"></font-awesome-icon></b-button>
            </b-col>
            <!-- <b-col>
              <b-button @click="selectAllImages"> Select all images</b-button>
            </b-col>
            <b-col>
              <b-button> Deselect all images</b-button>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
      <b-row :key="rerender" >
        <b-col md="3" v-for="(image, index) in paginatedData" :key="pageNumber + '_' + index" class="mb-2 gx-1"
          cols="3">
          <div class="image-container">
            <b-col class="mb-2 gx-1" >
            <input
            v-if=" (current_role === 'Administrator' || current_role === 'Data Loader' || current_role === 'Data Scientist')"
              type="checkbox"
              v-model="selectedImages[image._id]"
              class="image-checkbox"
            />
            <img
              :src="getRoute+'/image/' +image._id + '?thumb=yes'"
              alt="Uploaded Image"
              class="img-fluid"
              @load="onImageLoad(index)"
              @click="openBottomSheet(image)"
            />
            <div>
            <small class="image-name text-white">{{ image.image.name }}</small>
          </div>
            <!-- <button @click="showImageModal(image)" class="show-modal-button">
              <i class="fa fa-bars"></i>
            </button> -->
            <!-- Small indicator with red or green color based on 'trained' property -->
            <small class="indicator"
              :class="image.labelled > 0 ? 'trained-green': 'trained-red'"
            >
              {{ image.labelled > 0 ? 'Annotations: '+image.labelled : 'Not Annotated' }}
            </small>
          </b-col>
          </div>
        </b-col>
      </b-row>
    <div class="text-center" v-if="allImages.length === 0">
      <div class="empty-images">
        <i class="fa fa-image fa-3x"></i>
        <p>No images available.</p>
      </div>
    </div>
      <b-row align-h="center">
        <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
          <i class="fa fa-chevron-left"></i>
        </b-button>
        <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
          1
        </b-button>
        <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
          {{ pageNumber -1  }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
          {{ pageNumber }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
          {{ pageNumber +1 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
          {{ pageNumber +2 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
          {{ pageNumber +3 }}
        </b-button>
        <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
        <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
          {{maxPages}}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
          <i class="fa fa-chevron-right"></i>
        </b-button>
        <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
          <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
        </b-dropdown>
      </b-row>
    </div>
    <b-modal
      v-model="showModal"
      centered
      header-close-content="<i class='fa fa-times color-white'></i>"
      size="xl"
      hide-footer
    >
      <template #modal-title>
        <h4 class="mx-1 text-white">{{ selectedImage.image.name }}</h4>
      </template>
      <ImageViewerModal
      :image="selectedImage"
      :projectId="projectId"
      @closeModalChild="closeModal"
      @assiningSingle="assiningSingle" />
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ImageViewerModal from './ImageViewerModal.vue'
import { apiRoute } from '../../../../helpers'

export default {
  components: {
    ImageViewerModal
  },
  props: {
    projectId: null
  },
  data () {
    return {
      allImages: [],
      showModal: false,
      selectedImage: null,
      selectedImages: {},
      isLoading: false,
      sortflag: true,
      filterChoice: '',
      allImagesChache: [],
      search: '',
      rerender: 0,
      pageNumber: -0,
      itemsPerPage: 16,
      singleAssign: false
    }
  },
  computed: {
    ...mapState('projectSubmission', {
      images: (state) => state.allImages,
      allImagesWatch: (state) => state.loadingAllImages,
      selectedProjectId: (state) => state.selectedProjectId
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('auth', ['user']),
    current_role () {
      const currentMem = this.members.find(p => p._id === this.user.id)
      // // console.log('member restrict', currentMem)
      if (currentMem) {
        // console.log('member restrict', currentMem, this.selectedProjectId)
        const currentPro = currentMem.projectRole.find(p => p.project === this.selectedProjectId)
        if (currentPro) {
          // console.log('member restrict', currentPro.role)
          return currentPro.role
        } else {
          // console.log('member restrict', currentPro)
          return 'Administrator'
        }
      } else {
        return 'View Only'
      }
    },
    selectedImageCount () {
      return Object.values(this.selectedImages).filter(Boolean).length
    },
    getRoute () {
      return apiRoute()
    },
    filterChange () {
      return this.filterChoice
    },
    searchImg () {
      return this.search
    },
    imagesLoad () {
      return this.allImagesWatch
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.allImages.slice(start, end)
    },
    maxPages () {
      return (this.allImages.length / this.itemsPerPage >> 0) + 1
    }
  },
  watch: {
    filterChange (_new) {
      if (_new !== 'All') {
        // // // console.log('filter')
        this.allImages = []
        // // // console.log(this.allImages)
        // // // console.log(this.allImagesChache)
        this.allImagesChache.forEach(item => {
          let includeInFilter = false
          var _filter = this.projectId.subgroups.find(sg => sg.name === this.filterChoice).definiation
          // // // console.log(_filter)
          item.metadata.forEach(md => {
            md.values.forEach(val => {
              if (_filter[val.name] && _filter[val.name].includes(val.value)) {
                includeInFilter = true
              }
            })
          })
          if (includeInFilter) {
            this.allImages.push(item)
          }
        })
      } else {
        this.allImages = this.allImagesChache
      }
      // this.$emit('filterImages', _new)
    },
    searchImg (_new) {
      // // // console.log(this.allImagesChache)
      this.allImages = this.allImagesChache.filter(img => img.image.name.includes(this.search))
    },
    imagesLoad (_new) {
      if (!_new) {
        this.allImages = this.images
        this.allImagesChache = this.images
        this.isLoading = false
        this.singleAssign = false
        // console.log(this.selectedImageCount && this.singleAssign)
        this.rerender++
      }
    }
  },
  methods: {
    ...mapActions('projectSubmission', ['getAllImages2']),
    ...mapActions('projectSubmission', {
      assignImageToProject: 'assignImageToProject'
    }),
    assiningSingle () {
      // console.log('set spinner', this.selectedImageCount)
      this.singleAssign = true
      this.isLoading = true
      // console.log(this.selectedImageCount && this.singleAssign)
    },
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    toggleSort () {
      // // // console.log(this.sortflag)
      this.sortflag = !this.sortflag
      this.allImages.sort((a, b) => (a.labelled - b.labelled))
      if (this.sortflag) {
        this.allImages = this.allImages.reverse()
      }
    },
    showImageModal (image) {
      this.selectedImage = image
      this.showModal = true
    },
    closeModal () {
      // // // console.log('parent close')
      this.getAllImages2(this.projectId._id)
      this.$emit('reload')
      this.showModal = false
    },
    onImageLoad (index) {
      // // // // console.log('images are loading in this case', index)
    },
    async assignToProject () {
      const selectedImageIds = Object.keys(this.selectedImages).filter(
        (imageId) => this.selectedImages[imageId]
      )
      this.isLoading = true
      try {
        await this.assignImageToProject({
          projectId: this.projectId._id,
          imageIds: selectedImageIds
        })
        this.selectedImages = {}
        this.getAllImages2(this.projectId._id)
      } catch (error) {
        console.error('Error assigning images to project:', error)
      } finally {
        this.$emit('reload')
        this.isLoading = false
      }
    },
    toggleImageSelection (imageId) {
      this.$set(this.selectedImages, imageId, !this.selectedImages[imageId])
    },
    selectAllImages () {
      this.allImages.forEach(img => {
        // // // console.log(img._id)
        // this.selectedImages[img._id] = true
      })
    },
    openBottomSheet (image) {
      // // // console.log('abc', image)
      // Select the image
      this.selectedImage = {
        id: image._id,
        style: 'green',
        url: this.getRoute + '/image/' + image._id,
        details: {
          created: image.created_at,
          format: image.image.contentType,
          imgName: image.image_set && image.image_set.name ? image.image_set.name : image.image.name,
          iou: 0,
          image_set: image.image_set,
          otherImages: image.otherImages,
          meta: image.metadata,
          pathologies: null
        }
      }
      // // console.log('abc', this.selectedImage)

      this.$emit('open-bottom-sheet', this.selectedImage)
    }
  },
  mounted () {
    // this.getAllImages2(this.projectId._id)
    this.allImages = this.images
    this.allImagesChache = this.images
    this.rerender++
  }
}
</script>

<style scoped>
.all-images {
  border-radius: 10px;
  padding: 10px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.show-modal-button {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 2;
  background-color: #f0b93b;
  border: none;
  border-radius: 50%;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

.small-indicator {
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

/* Style the images */
.img-fluid {
  border-radius: 10px;
  border: 0.5px solid white;
}

.image-name {
  color: white;
  margin-top: 5px;
  font-size: 14px;
}

.trained-green {
  background-color: green;
}

.trained-red {
  background-color: #14304c;
}

.empty-images {
  color: #ccc;
  margin-top: 50px;
}

/* Style the checkbox */
.image-checkbox {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: -12px;
  left: -12px;
  cursor: pointer;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
}
</style>
