<template>
  <div class="text-center">
    <h5 class="text-white">
      <strong class="color-yellow"> Project metrics will be recalculated and this member's annotations will be deleted.</strong>
      <br/>
      <br/>
      Are you sure you want to remove <strong class="color-yellow">{{ this.member.personal.fullname }}</strong>
      from this project?
    </h5>
    <div class="d-flex justify-content-center mt-4">
      <b-button
        variant="danger"
        @click="removeUser"
        :disabled="loading"
        class="d-flex align-items-center rounded-pill bg-red"
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm mr-2"
        ></span>
        Remove
      </b-button>
      <b-button
        class="mx-2 rounded-pill"
        variant="secondary"
        @click="cancelDelete"
        :disabled="loading"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      selectedProjectId: (state) => state.projectSubmission.selectedProjectId
    })
  },
  methods: {
    ...mapActions('usersSubmission', {
      deleteUser: 'deleteUser',
      getMembers: 'getMembers'
    }),
    async removeUser () {
      this.loading = true
      await this.deleteUser({
        projectId: this.selectedProjectId,
        userId: this.member._id
      })
      await this.getMembers(this.selectedProjectId)
      this.loading = false
      this.$emit('delete-success')
    },
    cancelDelete () {
      this.$emit('delete-success')
    }
  }
}
</script>
