<template>
  <div class="container p-0 m-0 tabs">
    <div class="container p-0 m-0">
      <b-card-body class="h-100 p-0">
        <b-tabs card pills nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y scroll-overflow h-100" fill v-model="tabIndex">
          <b-tab title="Project Images" Active>
            <uploaded-images :projectId="projectId" :key="reloadRecords" @open-bottom-sheet="openBottomSheet"></uploaded-images>
          </b-tab>
          <b-tab title="Upload Images" v-if="(current_role === 'Administrator' || current_role === 'Data Loader' || current_role === 'Data Scientist')">
            <div class="list-containerDC mt-2">
              <div class="m-2 p-5 text-center upload-files-area">
                <div class="my-2 line-height-1">
                  <h5 class="mb-2">Upload Project Images</h5>
                  <p class="mb-2">
                    You can drag and drop images here or click the "Add" button
                    to select a folder for upload.
                  </p>
                  <file-upload
                    class="btn btn-primary btn-sm mt-4 px-5 rounded-pill"
                    ref="upload"
                    v-model="files"
                    :post-action="apiRoute"
                    :drop="true"
                    :multiple="true"
                    :directory="true"
                    :drop-directory="true"
                    :add-index="true"
                    :headers="headers"
                    :data="data"
                    :extensions="extensions"
                    :accept="accept"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                  >
                    Add
                  </file-upload>
                </div>
              </div>
              <div :class="{ 'modal-backdrop': true, fade: true, show: editFile.show }"></div>
              <div :class="{ modal: true, fade: true, show: editFile.show }" id="modal-edit-file" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Edit file</h5>
                      <button type="button" class="close" @click.prevent="closeEdit()">
                        <span>&times;</span>
                      </button>
                    </div>
                    <form @submit.prevent="onEditorFile">
                      <div class="modal-body">
                        <div class="form-group">
                          <label for="name">Name:</label>
                          <input type="text" class="form-control" required id="name" placeholder="Please enter a file name" v-model="editFile.name" />
                        </div>
                        <div class="form-group" v-if="editFile.show && editFile.blob && editFile.type && editFile.type.substr(0, 6) === 'image/'">
                          <div class="edit-image-tool">
                            <b-row>
                              <b-col cols="3">
                                <b-button type="button" pill class="btn-yellow mb-2" @click="editFile.cropper.rotate(-90)" title="cropper.rotate(-90)" style="width: 100%">
                                  Rotate: <font-awesome-icon icon="undo" aria-hidden="true"></font-awesome-icon>
                                </b-button>
                              </b-col>
                              <b-col cols="3">
                                <b-button type="button" pill class="btn-yellow mb-2" @click="editFile.cropper.rotate(90)" title="cropper.rotate(90)" style="width: 100%">
                                  Rotate:
                                  <font-awesome-icon icon="redo" aria-hidden="true"></font-awesome-icon>
                                </b-button>
                              </b-col>
                              <b-col cols="3">
                                <b-button type="button" pill class="btn-yellow mb-2 px-3" @click="editFile.cropper.crop()" title="cropper.crop()" style="width: 100%">
                                  Crop:
                                  <font-awesome-icon icon="crop" aria-hidden="true"></font-awesome-icon>
                                </b-button>
                              </b-col>
                              <b-col cols="3">
                                <b-button type="button" pill class="btn-yellow mb-2 px-3" @click="editFile.cropper.clear()" title="cropper.clear()" style="width: 100%">
                                  Cancel Crop:
                                  <font-awesome-icon icon="times" aria-hidden="true"></font-awesome-icon>
                                </b-button>
                              </b-col>
                            </b-row>
                          </div>
                          <label>Image:</label>
                          <div class="edit-image">
                            <img :src="editFile.blob" ref="editImage" />
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="closeEdit()">
                          Close
                        </button>
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="container mt-2">
                <b-row v-if="false">
                  <b-col>
                    <b-button class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleThumb()">Show Thumbnails</b-button>
                  </b-col>
                  <b-col>
                    <!-- <label for="upload_delay"> Upload Delay</label> -->
                    <!-- <b-form-input id='upload_delay' type="number" v-model="uploadDelay"></b-form-input> -->
                  </b-col>
                </b-row>
                <b-row v-if="files && files.length > 0">
                  <b-col cols="6">
                    <span v-if="uploadFileAuto">Uploading in: {{ this.uploadIn }}</span>
                    <span v-else-if="uploadFileAuto && uploadIn === 0">Uploading ... {{ this.files.length - 1 }} file left</span>
                    <span v-else>Auto-upload disabled</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click.prevent="startFileUpload()">
                      Start upload <font-awesome-icon icon="upload" class="ml-1"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click.prevent="clearFiles()">
                      Reset upload <font-awesome-icon icon="trash" class="ml-1"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click.prevent="autoUpload(false)" v-if="uploadFileAuto">
                      Disable Auto-upload <font-awesome-icon icon="times" class="ml-1"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click.prevent="autoUpload(true)" v-else>
                      Enable Auto-upload <font-awesome-icon icon="hourglass-start" class="ml-1"></font-awesome-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-list-group v-if="files && files.length > 0">
                  <b-list-group-item v-for="(file, $index) in files" :key="$index" :active="file.id === active">
                    <b-row class="mb-0">
                      <b-col>
                        <div class="title">
                          <font-awesome-icon icon="arrow-circle-right" class="mr-2"></font-awesome-icon>
                          <strong class="color-white font-size-14">{{ file.name }}</strong> <small class="text-white">- {{ file.size | formatSize }}</small>
                        </div>
                      </b-col>
                      <b-col cols="2" class="text-center">
                        <div class="title">
                          <span v-if="parseInt(file.progress) === 0">
                            Pending
                          </span>
                          <span v-else-if="parseInt(file.progress) > 0 && parseInt(file.progress) < 100">
                            Uploading <small>- {{ parseInt(file.progress) }}%</small>
                          </span>
                          <span v-else-if="file.error && file.error !== ''">
                            Error <small class="text-danger">- {{ file.error }}</small>
                          </span>
                          <span v-else-if="file.success">
                            Uploaded
                          </span>
                        </div>
                      </b-col>
                      <b-col cols="2" class="text-right">
                        <b-button class="color-white" variant="link" :disabled="file.success" @click.prevent="deleteFile(file)">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </b-button>
                        <!-- <b-button class="color-white mx-2" variant="link" :disabled="parseInt(file.progress) > 0" @click.prevent="onEditFileShow(file)">
                          <font-awesome-icon icon="pen"></font-awesome-icon>
                        </b-button> -->
                        <b-button class="color-white" variant="link" :disabled="parseInt(file.progress) > 0" @click.prevent="uploadNow(file)">
                          <font-awesome-icon icon="upload"></font-awesome-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-tab>
          <b-tab title="Upload Metadata" v-if="(current_role === 'Administrator' || current_role === 'Data Loader' || current_role === 'Data Scientist' )">
            <div class="list-containerDC mt-2">
              <div class="m-2 p-5 text-center upload-files-area">
                <div class="my-2 line-height-1">
                  <h5 class="mb-2">Upload Image Metadata</h5>
                  <p class="mb-2">
                    You can drag and drop <b>csv</b>, <b>txt</b> or <b>json</b> files here or click the "Add" button
                    to select multiple files for upload.
                  </p>
                  <file-upload
                    class="btn btn-primary btn-sm mt-4 px-5 rounded-pill"
                    ref="uploadmd"
                    v-model="filesmd"
                    :post-action="apiRouteMD"
                    :drop="true"
                    :multiple="true"
                    :directory="true"
                    :drop-directory="true"
                    :add-index="true"
                    :headers="headers"
                    :data="data"
                    @input-filter="inputFilterMD"
                    @input-file="inputFileMD"
                  >
                    Add
                  </file-upload>
                </div>
              </div>
              <div class="container mt-2">
                <b-list-group>
                  <b-list-group-item v-for="(file, $index) in filesmd" :key="$index" :active="file.id === active">
                    <b-row class="mb-0">
                      <b-col cols="2">
                        <font-awesome-icon icon="file-csv" class="color-yellow" size="4x" v-if="file.type.indexOf('csv') >= 0"></font-awesome-icon>
                        <font-awesome-icon icon="file-code" class="color-yellow" size="4x" v-else-if="file.type.indexOf('json') >= 0"></font-awesome-icon>
                        <font-awesome-icon icon="file-alt" class="color-yellow" size="4x" v-else></font-awesome-icon>
                      </b-col>
                      <b-col>
                        <strong class="color-white" style="font-size: 14px">{{ file.name }}</strong><br />
                        <small class="text-white">{{ file.size | formatSize }}</small>
                      </b-col>
                      <b-col class="text-right pr-2">
                        <b-button class="bg-yellow color-black mr-2" variant="link" :disabled="file.success" @click.prevent="deleteFileMD(file)">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </b-button>
                        <b-button class="bg-yellow color-black" variant="link" :disabled="parseInt(file.progress) > 0" @click.prevent="uploadNowMD(file)">
                          <font-awesome-icon icon="upload"></font-awesome-icon>
                        </b-button>
                        <p class="mb-0 mt-1"><small>Uploading in {{ file.seconds }} seconds</small></p>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-tab>
          <b-tab title="Image Library">
            <all-images :projectId="projectId" @reload="reload" @open-bottom-sheet="openBottomSheet"></all-images>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import { apiRoute } from './../../../helpers'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import ImageCompressor from '@xkeshi/image-compressor'
import md5 from 'js-md5'
import { mapActions, mapState } from 'vuex'
import UploadedImages from './DataCollection/UploadedImages.vue'
import AllImages from './DataCollection/AllImages.vue'

export default {
  components: {
    UploadedImages,
    AllImages
  },
  props: {
    projectId: null
  },
  data () {
    return {
      projectImagesLoaded: false,
      active: null,
      files: [],
      filesmd: [],
      currentTab: 'uploadedImages',
      extensions: 'jpeg|jpe|jpg|png|tiff|bmp|dcm||nii',
      accept: 'image/png,image/jpeg',
      uploadAuto: false,
      editFile: {
        show: false,
        name: ''
      },
      uploadIn: 30,
      uploadDelay: '3',
      noOfFiles: 0,
      noOfFilesMD: 0,
      headers: {
        Authorization: 'Bearer '
      },
      data: {
        _hash: null,
        user: { _id: null }
      },
      tabIndex: 0,
      reloadRecords: 0,
      showThumb: false,
      uploadFileInterval: null,
      uploadFileAuto: true,
      uploadThisFile: null,
      uploadingFiles: false
    }
  },
  created () {
    fetch('https://api.ipify.org?format=json')
      .then((x) => x.json())
      .then(({ ip }) => {
        this.data._hash = md5(ip + Date.now().toString())
        this.data.user._id = this.user.id
      })
  },
  mounted () {
    if (this.user) {
      this.headers.Authorization = 'Bearer ' + this.user.token
    } else {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth
    }),
    ...mapState('projectSubmission', {
      selectedProjectId: (state) => state.selectedProjectId
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('auth', ['user']),
    current_role () {
      const currentMem = this.members.find(p => p._id === this.user.id)
      // // console.log('member restrict', currentMem)
      if (currentMem) {
        // console.log('member restrict', currentMem, this.selectedProjectId)
        const currentPro = currentMem.projectRole.find(p => p.project === this.selectedProjectId)
        if (currentPro) {
          // console.log('member restrict', currentPro.role)
          return currentPro.role
        } else {
          // console.log('member restrict', currentPro)
          return 'Administrator'
        }
      } else {
        return 'View Only'
      }
    },
    user () {
      if (this.auth && this.auth.status && this.auth.status.loggedIn) {
        return this.auth.user
      } else {
        return null
      }
    },
    apiRoute () {
      return apiRoute() + '/api/v1/admin/training/images/upload?projectId=' + this.projectId._id
    },
    apiRouteMD () {
      return apiRoute() + '/api/v1/admin/training/images/metadata/' + this.projectId._id
    },
    watchDelay () {
      return this.uploadDelay
    },
    isQueueEmpty () {
      return this.files.length
    },
    uploadFileLength () {
      return this.files.length
    }
  },
  methods: {
    ...mapActions('alert', ['success', 'error']),
    ...mapActions('auth', ['increaseImagesToday']),
    ...mapActions('projectSubmission', {
      getProjectImages: 'getProjectImages2'
    }),
    clearFiles () {
      if (this.files && this.files.length > 0) {
        this.files.forEach(file => {
          this.$refs.upload.remove(file)
        })
      }
    },
    autoUpload (state) {
      this.uploadFileAuto = state
      if (state) {
        this.uploadIn = 30
        this.uploadFileInterval = setInterval(() => {
          if (this.uploadIn > 0) {
            this.uploadIn -= 1
          } else {
            this.startFileUpload()
          }
        }, 1000)
      } else {
        this.uploadIn = 0
        clearInterval(this.uploadFileInterval)
        this.uploadFileInterval = null
      }
    },
    startFileUpload () {
      if (this.files && this.files.length > 0) {
        clearInterval(this.uploadFileInterval)
        this.uploadFileInterval = null
        this.uploadIn = 0
        this.uploadFileAuto = true
        this.uploadThisFile = this.files[0].id
        this.uploadingFiles = true
      }
    },
    toggleThumb () {
      // console.log('t', this.showThumb)
      this.showThumb = !this.showThumb
    },
    reload () {
      // // console.log('upload saw this')
      this.reloadRecords += 1
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }

        if (!/\.(jpeg|jpe|jpg|png|tiff|bmp|dcm|nii)$/i.test(newFile.name)) {
          this.error('File: ' + newFile.name + ' is not an image file, please upload an image file.')
          return prevent()
        }

        // console.log('File', newFile)
        if (1 === 2 && newFile.file && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512
          })
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type
              })
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || 'compress'
              })
            })
        }
      }

      if (1 === 2 && newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }

        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    },
    inputFilterMD (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        if (!/\.(csv|txt)$/i.test(newFile.name)) {
          this.error('File: ' + newFile.name + ' is not a supported file type, please upload an .csv or .txt file.')
          return prevent()
        }

        if (newFile.file && (newFile.type.substr(0, 6) === 'text/' || newFile.type === 'application/json')) {
          this.$refs.uploadmd.update(newFile, {
            error: '',
            newFile,
            size: newFile.size,
            type: newFile.type
          })
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      }
    },
    inputFile (newFile, oldFile) {
      if (!newFile && oldFile) {
        // // console.log('Old file: ', oldFile.name)
      }

      if (newFile && oldFile) {
        if (newFile.progress !== oldFile.progress) {
          // progress
        }

        if (newFile.error && !oldFile.error) {
          // error
        }

        if (newFile.success && !oldFile.success) {
          /*
          this.success(
            'File successfully submitted for processing, removing from upload list.'
          )
          this.reload()
          this.increaseImagesToday()
          setTimeout(this.deleteFile(newFile), 500)
          this.$emit('refresh-studies', true)
          */
          setTimeout(this.deleteFile(newFile), 500)
          if (this.uploadFileAuto) {
            if (this.files && this.files.length > 0) {
              this.uploadThisFile = this.files[0].id
            } else {
              this.reload()
              this.success('Files successfully uploaded.')
              this.$emit('refresh-studies', true)
            }
          }
        }
      }
    },
    inputFileMD (newFile, oldFile) {
      if (newFile && !oldFile) {
        newFile.seconds = this.uploadIn + this.noOfFilesMD++ * 2
        newFile.countdown = setInterval(() => {
          if (newFile.seconds > 1) {
            newFile.seconds -= 1
            this.$refs.uploadmd.update(newFile, { seconds: newFile.seconds })
          } else {
            clearInterval(newFile.countdown)
            newFile.countdown = null
            this.$refs.uploadmd.update(newFile, { countdown: null })
            this.$refs.uploadmd.update(newFile, { active: true })
          }
        }, 1000)
      }

      if (!newFile && oldFile) {
        // // console.log('Old file: ', oldFile.name)
      }

      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active) {
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.uploadmd.update(newFile, { error: 'size' })
          }
        }

        if (newFile.progress !== oldFile.progress) {
          // progress
        }

        if (newFile.error && !oldFile.error) {
          // error
        }

        if (newFile.success && !oldFile.success) {
          this.success(
            'File successfully submitted for processing, removing from upload list.'
          )
          this.reload()
          setTimeout(this.deleteFileMD(newFile), 500)
          this.$emit('refresh-studies', true)
        }
      }

      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.uploadmd.active) {
          this.$refs.uploadmd.active = true
        }
      }
    },
    onAddFolder () {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support')
        return
      }

      const input = this.$refs.upload.$el.querySelector('input')
      input.directory = true
      input.webkitdirectory = true
      this.directory = true

      input.onclick = null
      input.click()
      input.onclick = () => {
        this.directory = false
        input.directory = false
        input.webkitdirectory = false
      }
    },
    deleteFile (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.upload.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.upload.update(file, { seconds: 0 })
      }
      this.$refs.upload.remove(file)
    },
    deleteFileMD (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.uploadmd.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.uploadmd.update(file, { seconds: 0 })
      }
      this.$refs.uploadmd.remove(file)
    },
    uploadNow (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.upload.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.upload.update(file, { seconds: 0 })
      }
      this.$refs.upload.update(file, { active: true })
      // // console.log('file is being uploaded here and now')
    },
    uploadNowMD (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.uploadmd.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.uploadmd.update(file, { seconds: 0 })
      }
      this.$refs.uploadmd.update(file, { active: true })
      // // console.log('file is being uploaded here and now')
    },
    onEditFileShow (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.upload.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.upload.update(file, { seconds: 0 })
      }
      this.editFile = { ...file, show: true }
      this.$refs.upload.update(file, { error: 'edit' })
    },
    onEditorFile () {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support')
        this.editFile.show = false
        return
      }

      const data = {
        name: this.editFile.name
      }

      if (this.editFile.cropper) {
        const binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(',')[1]
        )
        const arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        data.file = new File([arr], data.name, { type: this.editFile.type })
        data.size = data.file.size
      }
      this.$refs.upload.update(this.editFile.id, data)
      this.editFile.error = ''
      this.editFile.show = false

      this.editFile.seconds = this.uploadIn
      this.editFile.countdown = setInterval(() => {
        if (this.editFile.seconds > 1) {
          this.editFile.seconds -= 1
          this.$refs.upload.update(this.editFile.id, {
            seconds: this.editFile.seconds
          })
        } else {
          clearInterval(this.editFile.countdown)
          this.editFile.countdown = null
          this.$refs.upload.update(this.editFile.id, { countdown: null })
          this.$refs.upload.update(this.editFile.id, { active: true })
        }
      }, 1000)
      this.$refs.upload.update(this.editFile.id, {
        countdown: this.editFile.countdown
      })
    },
    closeEdit () {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support')
        this.editFile.show = false
        return
      }

      this.editFile.error = ''
      this.editFile.show = false

      this.editFile.seconds = this.uploadIn
      this.editFile.countdown = setInterval(() => {
        if (this.editFile.seconds > 1) {
          this.editFile.seconds -= 1
          this.$refs.upload.update(this.editFile.id, {
            seconds: this.editFile.seconds
          })
        } else {
          clearInterval(this.editFile.countdown)
          this.editFile.countdown = null
          this.$refs.upload.update(this.editFile.id, { countdown: null })
          this.$refs.upload.update(this.editFile.id, { active: true })
        }
      }, 1000)
      this.$refs.upload.update(this.editFile.id, {
        countdown: this.editFile.countdown
      })
    },
    openBottomSheet: function (image) {
      this.$emit('open-bottom-sheet', image)
    }
  },
  watch: {
    isQueueEmpty (_new) {
      // console.log('que', _new)
      if (_new === 0) {
        this.getProjectImages(this.projectId._id)
        this.noOfFiles = 0
        this.noOfFilesMD = 0
      }
    },
    watchDelay (_new) {
      this.uploadIn = parseInt(_new)
      // console.log('new delay', this.uploadIn)
    },
    'editFile.show' (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ''
        })
      }

      if (newValue) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          const cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false
          })
          this.editFile = {
            ...this.editFile,
            cropper
          }
        })
      }
    },
    uploadFileLength () {
      if (this.uploadFileLength > 0) {
        if (!this.uploadingFiles) {
          if (this.uploadIn === 0) {
            this.uploadIn = 30
          }

          this.uploadFileInterval = setInterval(() => {
            if (this.uploadIn > 0) {
              this.uploadIn -= 1
            } else {
              this.startFileUpload()
            }
          }, 1000)
        }
      } else {
        this.uploadingFiles = false
      }
    },
    uploadThisFile () {
      if (this.uploadThisFile) {
        const _file = this.files.find(file => file.id === this.uploadThisFile)
        this.$refs.upload.update(_file, { active: true })
      }
    }
  }
}
</script>
<style scoped></style>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.upload-files-container {
  height: 600px;
  overflow-y: auto;
}
.width-80 {
  width: 80%;
  border: 1px solid #14304c;
}
.list-group-item {
  background-color: inherit !important;
  transition: background-color 0.3s ease;
  padding: 0.25rem 0.5rem;
  border: none;
}

.list-group-item:not(:last-child) {
  border-bottom: 1px solid #14304c;
}

.list-group-item .title {
  padding: 0.35rem 0.75rem;
}

.btn-link {
  padding: 0.25rem 0.25rem;
}

.list-group-item:hover {
  background-color: #0e243a !important;
}
.upload-files-area {
  border-radius: 1.25rem;
}
.upload-files-area:hover {
  background-color: #14304c33;
}
.line-height-1 {
  line-height: 1.2;
}
.modal-backdrop.fade {
  visibility: hidden;
}
.modal-backdrop.fade.show {
  visibility: visible;
}
.fade.show {
  display: block;
  z-index: 1072;
}
.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.nav-item.active a.nav-link {
  background-color: #f0b93b;
  color: #040303;
  font-weight: 600;
}
.nav-item:not(.active) a.nav-link {
  background-color: #14304c;
  color: #fff;
}
</style>
