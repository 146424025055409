<template>
  <b-card no-body class="all-images mt-2 h-100 bg-dark-navy custom border-0">
    <template v-slot:header>
      <b-row class=" mb-0">
        <b-col cols="8">
          <font-awesome-icon :icon="getIcon()" size="lg" class="mr-1"></font-awesome-icon> {{ getPhase() }}
        </b-col>
        <b-col cols="4" align-self="center" class="text-right">
          <h6 class="color-white pt-0 mb-0">{{ projects.find((project) => project._id === projectId).name }}</h6>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0">
      <div class="px-3 py-2">
        <b-row align-h="between" class="pb-0 mb-0">
          <b-col align-self="end" cols="9">
            <h6 class="text-white ml-2 mt-2">Reader Summary</h6>
          </b-col>
          <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
            <b-col cols="6" class="py-0 mx-0 px-1">
              <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{
              sg.name }}</b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="6" class="px-1 py-0 mx-0">
              <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill" :text="tsChoice.toString()" variant="warn">
                <b-dropdown-item v-for="ts in thesholds" :key="ts + projectId" @click="tsChoice = ts">{{ ts
                  }}</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-row>
        <hr class="mt-0 border-info" />
        <b-row>
          <b-col cols="4" class="mt-2">
            <apexchart height="320" :options="variabilityOptions" :series="variabilitySeries"></apexchart>
          </b-col>
          <!-- <b-col cols="4" class="mt-2"> -->
          <!-- v-if="currentProject.studyType === 'Prospective'" -->
          <!-- <apexchart height="320" :options="readerOptions" :series="readerSeries"></apexchart>
          </b-col> -->
          <b-col cols="4" class="mt-2">
            <apexchart height="320" :options="readerOptions" :series="readerSeries"></apexchart>
          </b-col>
          <b-col cols="4" class="mt-2">
            <apexchart height="320" :options="moderatorOptions" :series="moderatorSeries"></apexchart>
          </b-col>
          <b-col cols="12" class="mt-2">
            <apexchart height="150" :options="dataOptions" :series="dataSeries"></apexchart>
          </b-col>
        </b-row>
        <!-- v-if="currentProject.counts[10]" -->
        <h6 class="text-white ml-2 mt-1">AI Performance Summary</h6>
        <hr class="mt-0 border-info" />
        <b-row>
          <b-col cols="4" class="mt-2">
            <apexchart height="320" :options="cmOptions" :series="cmSeries"></apexchart>
          </b-col>
          <b-col cols="4" class="mt-2">
            <apexchart :options="aucOptions" :series="aucSeries"></apexchart>
          </b-col>
          <b-col cols="4" class="mt-2">
            <apexchart :options="prOptions" :series="prSeries"></apexchart>
          </b-col>
          <!-- <b-col cols="3" v-if="false">
            <b-row class="mt-2 mx-1">
              <b-col cols="10" class="text-center pb-2" style="border-bottom: 1px solid white;"><strong class="color-white">Actual</strong></b-col>
              <b-col cols="2" class="px-0" style="max-width: 2rem;"></b-col>
            </b-row>
            <b-row class="mb-2 mx-1">
              <b-col cols="10">
                <b-row>
                  <b-col class="text-center py-4" cols="6">
                    <h6 class="color-white mb-0">{{ confusionMatrix.truePositive }}</h6>
                    <p class="mb-0"><small>True Positive</small></p>
                  </b-col>
                  <b-col class="text-center py-4" cols="6">
                    <h6 class="color-white mb-0">{{ confusionMatrix.falsePositive }}</h6>
                    <p class="mb-0"><small>False Positive</small></p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center py-4" cols="6">
                    <h6 class="color-white mb-0">{{ confusionMatrix.falseNegative }}</h6>
                    <p class="mb-0"><small>False Negative</small></p>
                  </b-col>
                  <b-col class="text-center py-4" cols="6">
                    <h6 class="color-white mb-0">{{ confusionMatrix.trueNegative }}</h6>
                    <p class="mb-0"><small>True Negative</small></p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="bottom-to-top text-center pl-2 pr-0" style="border-left: 1px solid white; max-width: 2rem;"><strong class="color-white">Predicted</strong></b-col>
            </b-row>
          </b-col> -->
        </b-row>
      </div>
    </b-card-body>
    <b-card-footer class="scrollable">
      <b-row v-if="!expand">
        <b-col cols="12" class="text-center">
          <a href="#" @click.prevent="expand = !expand">
            <font-awesome-icon icon="chevron-up" size="2x" />
          </a>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" class="text-center">
          <a href="#" @click.prevent="expand = !expand">
            <font-awesome-icon icon="chevron-down" size="2x" />
          </a>
        </b-col>
        <b-col cols="12">
          <b-table striped hover :items="metrics">
            <template #cell(threshold)="data">
              <small>{{ data.item.threshold.toFixed(2) }}</small>
            </template>
            <template #cell(TP)="data">
              <b-badge pill class="bg-green color-black py-1 px-2 font-weight-400">
                {{ data.item.TP }}
              </b-badge>
            </template>
            <template #cell(FP)="data">
              <b-badge pill class="bg-red color-white py-1 px-2 font-weight-400">
                {{ data.item.FP }}
              </b-badge>
            </template>
            <template #cell(FN)="data">
              <b-badge pill class="bg-red color-white py-1 px-2 font-weight-400">
                {{ data.item.FN }}
              </b-badge>
            </template>
            <template #cell(TN)="data">
              <b-badge pill class="bg-green color-black py-1 px-2 font-weight-400">
                {{ data.item.TN }}
              </b-badge>
            </template>
            <template #cell(total)="data">
              <small>{{ data.item.total.toFixed(0) }}</small>
            </template>
            <template #cell(accuracy)="data">
              <small>{{ data.item.accuracy.toFixed(2) }}</small>
            </template>
            <template #cell(sensitivity)="data">
              <small>{{ !Number.isNaN(data.item.sensitivity) ? data.item.sensitivity.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(specificity)="data">
              <small>{{ !Number.isNaN(data.item.specificity) ? data.item.specificity.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(TPR)="data">
              <small>{{ data.item.TPR && data.item.TPR >= 0 ? data.item.TPR.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(NPV)="data">
              <small>{{ data.item.NPV && data.item.NPV >= 0 ? data.item.NPV.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(FPR)="data">
              <small>{{ data.item.FPR && data.item.FPR >= 0 ? data.item.FPR.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(F1)="data">
              <small>{{ data.item.F1 && data.item.F1 >= 0 ? data.item.F1.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(recall)="data">
              <small>{{ data.item.recall && data.item.recall >= 0 ? data.item.recall.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(precision)="data">
              <small>{{ data.item.precision && data.item.precision >= 0 ? data.item.precision.toFixed(2) : '-'
                }}</small>
            </template>
            <template #cell(error)="data">
              <small>{{ data.item.error && data.item.error >= 0 ? data.item.error.toFixed(2) : '-' }}</small>
            </template>
            <template #cell(_95_CI)="data">
              <small>{{ data.item._95_CI && data.item._95_CI >= 0 ? data.item._95_CI.toFixed(4) : '-' }}</small>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<style>
.scrollable {
  overflow-y: auto;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { IOVThesholds } from './../../../../helpers/iovthresholds'

export default {
  name: 'SummaryResults',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    selected: {
      type: Number,
      default: null
    },
    projectId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      filterChoice: '',
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'Project Details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 10, stub: 'feedback', name: 'Inference feedback', icon: 'crop' },
          { id: 11, stub: 'moderation', name: 'Feedback moderation', icon: 'check-double' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      expand: false,
      options: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      pieOptions: {
        chart: {
          type: 'donut'
        },
        title: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          offsetY: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        dataLabels: {
          style: {
            fontFamily: 'Lato, sans-serif',
            fontSize: '8px'
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'top'
            }
          }
        }]
      },
      barOptions: {
        chart: {
          type: 'bar',
          height: 100,
          stacked: true,
          stackType: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '14px'
          }
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: true,
          offsetY: 9,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: '#ffffff'
          }
        }
      },
      imgAiData: [],
      imgData: [],
      proImg: [],
      fa: 0,
      pd: 0,
      fd: 0,
      conReader: 0,
      disReader: 0,
      conMod: 0,
      disMod: 0,
      accuracy: [],
      rejections: [],
      trueP: 0,
      falseP: 0,
      falseN: 0,
      IOV: 0,
      notAnnotated: 0,
      partiallyAnnotated: 0,
      fullyAnnotated: 0,
      moderated: 0,
      processed: 0,
      failProcessed: 0,
      aiMetrics: [],
      tsChoice: 0.5
    }
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectSubmission.projects,
      userProjects: (state) => state.projectSubmission.projects
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('projectSubmission', {
      _project: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    thesholds () {
      return Array.from({ length: 21 }, (_, i) => parseFloat((i * 0.05).toFixed(2)))
    },
    getAi () {
      return this.images.aiLoaded
    },
    getAiStats () {
      return this._project.statsLoading
    },
    project () {
      const filteredProject = this.userProjects.find(
        (project) => project._id === this.projectId
      )
      // // // // console.log('filtered project', filteredProject)
      return filteredProject
    },
    imagesLoaded () {
      return this.images.loaded
    },
    // computeCharts () {
    //   variabilityOptions
    //   readerOptions
    //   moderatorOptions
    //   cmOptions
    //   aucOptions
    //   prOptions
    // },
    variabilityOptions () {
      // if (this.currentProject.studyType === 'Retrospective') {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Full Agreement', 'Partial Disagreement', 'Full Disagreement']
      _options.colors = ['#28a745', '#ffc107', '#dc3545']
      _options.title.text = !this.IOV ? 'Not Enough Data' : (this.IOV * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Inter-Observer Variability'

      return _options
      // }
    },
    variabilitySeries () {
      return [this.fa, this.pd, this.fd]
    },
    readerOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Fully Annotated', 'Partially Annotated', 'Not Annotated']
      _options.colors = ['#28a745', '#ffc107', '#dc3545']
      _options.title.text = 'Data Annotation'
      _options.subtitle.text = 'Progress Summary'

      return _options
    },
    readerSeries () {
      return [this.fullyAnnotated, this.partiallyAnnotated, this.notAnnotated]
    },
    moderatorOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Moderated', 'Rejected']
      _options.colors = ['#28a745', '#dc3545']
      _options.title.text = 'Data Moderation'
      _options.subtitle.text = 'Progress Summary'
      return _options
    },
    moderatorSeries () {
      return [this.moderated, this.rejections.reduce((a, b) => a + b.count, 0)]
    },
    dataOptions () {
      const _options = JSON.parse(JSON.stringify(this.barOptions))
      _options.title.text = undefined

      return _options
    },
    dataSeries () {
      return [{
        name: 'Processed',
        data: [this.images.aiImages.length]
      }, {
        name: 'Failed during Processing',
        data: [this.failed]
      }, {
        name: 'Rejected on Data Moderation',
        data: [this.rejections.find(r => r.phase === 3) ? this.rejections.find(r => r.phase === 3).count : 0]
      }, {
        name: 'Partially Annotated',
        data: [this.partiallyAnnotated]
      }, {
        name: 'Not Annotated',
        data: [this.notAnnotated]
      }, {
        name: 'Rejected on Data Processing',
        data: [this.rejections.find(r => r.phase === 2) ? this.rejections.find(r => r.phase === 2).count : 0]
      }, {
        name: 'Rejected on Reference Standard',
        data: [this.rejections.find(r => r.phase === 4) ? this.rejections.find(r => r.phase === 4).count : 0]
      }, {
        name: 'Rejected on Data Collection',
        data: [this.rejections.find(r => r.phase === 1) ? this.rejections.find(r => r.phase === 1).count : 0]
      }]
    },
    failed () {
      if (this.imgAiData && this.imgAiData.length > 0) {
        return this.imgAiData.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)) && (img.diagnosis && (img.diagnosis === 'Error' || img.diagnosis === 'Unknown'))).length
      } else {
        return 0
      }
    },
    cmOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['True Positive', 'False Positive', 'False Negative', 'True Negative']
      _options.title.text = (this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive +
        this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative === 0 ? 'Not Enough Data' : (Math.round(
          (this.confusionMatrix.truePositive + this.confusionMatrix.trueNegative) /
          (this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive +
            this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative) * 10000) / 100).toFixed(2) + '%')
      _options.subtitle.text = 'Accuracy of AI Solution'

      return _options
    },
    cmSeries () {
      return [this.confusionMatrix.truePositive, this.confusionMatrix.falsePositive, this.confusionMatrix.falseNegative, this.confusionMatrix.trueNegative]
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.003175) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    aucOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.auc.toFixed(4)
      _options.subtitle.text = 'Area Under Curve'

      return _options
    },
    aucSeries () {
      const _sorted = this.metrics.slice()
      _sorted.sort((a, b) => { return a.FRP - b.FRP })

      return [{
        data: _sorted.map(__metrics => { return { x: __metrics.FPR, y: (__metrics.TPR > 1 ? __metrics.TPR : __metrics.TPR) } })
      }]
    },
    prOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.metrics.find(__metrics => __metrics.threshold === 0.8) ? this.metrics.find(__metrics => __metrics.threshold === 0.8).accuracy.toFixed(4) : 0
      _options.subtitle.text = 'Precision-Recall Curve'

      return _options
    },
    prSeries () {
      return [{
        data: this.metrics.map(__metrics => { return { x: __metrics.precision !== null ? __metrics.precision : 0, y: __metrics.recall !== null ? __metrics.recall : 0 } })
      }]
    },
    rejectWatch () {
      return !this._project.loadingRejects
    },
    readers () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    projectImagesLoaded () {
      return !this._project.loadingProjectImages
    },
    confusionMatrix () {
      const _at50 = this.aiMetrics.find(_record => _record.threshold === this.tsChoice)

      if (_at50) {
        return {
          truePositive: _at50.TP,
          falsePositive: _at50.FP,
          falseNegative: _at50.FN,
          trueNegative: _at50.TN
        }
      } else {
        return {
          truePositive: 0,
          falsePositive: 0,
          falseNegative: 0,
          trueNegative: 0
        }
      }
    },
    metrics () {
      return this.aiMetrics
    },
    dataLoaded () {
      return (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch)
    },
    thesholdChange () {
      return this.tsChoice
    },
    aiStatsLoading () {
      return this._project.aiStatsLoading
    },
    filterWatch () {
      return this.filterChoice
    }
  },
  mounted () {
    // // // console.log('ab')
    this.imgAiData = this.images.aiImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
    this.proImg = this._project.projectImages
    this.imgData = this.images.labelledImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
    this.rejections = this._project.rejects
    if (this._project && this._project.stats) {
      const curIndex = this._project.stats.findIndex(p => p.projectId === this.projectId)
      // console.log('ai ret', curIndex)
      if (curIndex > -1) {
        this.tsChoice = this._project.stats[curIndex].confiThes
      }
    }
    this.getChartData()
    this.calcMetrics()
  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'updateConfidence']),
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            // // console.log(img.data.details)
            if (img.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.metadata && img.metadata[0] && img.metadata[0] && img.metadata[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.metadata[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    getChartData () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch && !this.getAiStats) {
        // // console.log(this.getAi)
        // // console.log(this.projectImagesLoaded)
        // // console.log(this.imagesLoaded)
        // // console.log(this.rejectWatch)
        this.moderated = 0
        this.fullyAnnotated = 0
        this.partiallyAnnotated = 0
        this.moderated = 0
        this.fa = 0
        this.pd = 0
        this.fd = 0
        var runningAcc = 0
        var totalAcc = 0
        let _imgData
        if (this.filterChoice !== 'All' && this.filterChoice !== '') {
          _imgData = this.imgData.filter(img => this.hasGroup(img, this.filterChoice))
        } else {
          _imgData = this.imgData
        }
        _imgData.forEach(img => {
          if (!img.removed.find(p => p.project === this.project._id)) {
            if (img.users.find(us => us.role === 'Moderator')) {
              // // console.log('mod')
              this.moderated += 1
              this.fullyAnnotated += 1
            } else if (img.users.length < this.readers.length && img.users.length > 0) {
              this.partiallyAnnotated += 1
            } else if (img.users.length > 0 && img.users.length >= this.readers.length) {
              this.fullyAnnotated += 1
            }
            var acc
            if (img.accuracy) {
              acc = img.accuracy
            } else {
              acc = 0
            }
            if (this.readers.length) {
              runningAcc += acc
              totalAcc += 1
              // // console.log('acc', acc)
              if (acc <= IOVThesholds().fullAgreement) {
                this.fa++
              } else if (acc >= IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                this.pd++
              } else {
                this.fd++
              }
            }
          }
        })
        // console.log('iov check', runningAcc, totalAcc)
        this.IOV = runningAcc / totalAcc
        this.notAnnotated = this.proImg.length - this.fullyAnnotated - this.partiallyAnnotated
      }
      // // // console.log('chart')
    },
    getPhase: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.name
      } else {
        return ''
      }
    },
    getIcon: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        // // console.log('_phase', _project.type.toLowerCase())
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.icon
      } else {
        return ''
      }
    },
    calcMetrics: function () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch && !this.getAiStats) {
        const projectStats = this._project.stats.find(p => p.projectId === this.projectId)
        // console.log('ai2', projectStats, this.projectId)
        let met
        if (this.filterChoice === '' || this.filterChoice === 'All') {
          met = projectStats.stats.find(p => p.subgroup === 'All')
        } else {
          met = projectStats.stats.find(p => p.subgroup === this.filterChoice)
        }
        // console.log('ai3', met)
        if (met) {
          this.aiMetrics = met.metrics
        }
      }
    }
  },
  watch: {
    getAi (_new) {
      if (_new) {
        this.imgAiData = this.images.aiImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    projectImagesLoaded (_new) {
      if (_new) {
        this.proImg = this._project.projectImages
        this.getChartData()
        this.calcMetrics()
      }
    },
    imagesLoaded (_new) {
      if (_new) {
        this.imgData = this.images.labelledImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    // add stats watcher
    rejectWatch (_new) {
      if (_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    thesholdChange (_new) {
      this.rejections = this._project.rejects
      this.getChartData()
      this.calcMetrics()
      this.updateConfidence({ confiThes: this.tsChoice, projectId: this.projectId })
    },
    filterWatch (_new) {
      this.rejections = this._project.rejects
      this.getChartData()
      this.calcMetrics()
    },
    aiStatsLoading (_new) {
      if (!_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    dataLoaded (_new) {
      // // console.log(_new)
    }
  }
}
</script>

<style>
.bottom-to-top {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
</style>
