<template>
  <b-card no-body class="h-100 bg-dark-navy custom border-0 p-4">
    <b-input-group class="mb-3" v-if="!loading">
      <b-form-input
      class = "search rounded-tr-0 rounded-br-0 rounded-pill"
        v-model="searchQuery"
        placeholder="Search users..."
      ></b-form-input>
      <b-input-group-append>
        <b-button class="search" @click="clearSearch">
          Clear
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <div class="d-flex justify-content-end mb-2" v-if="(current_role === 'Administrator')">
      <b-button
        :disabled="info.licensedUserList.length <= this.filteredUsers.length"
        class="rounded-pill btn-yellow color-black"
        @click="openAddMembers"
        ><i class="fas fa-plus"></i> Add Members</b-button
      >
    </div>
    <div :key=" 'jkh' + rerender">
    <b-table
      v-if="!loading"
      :responsive="true"
      :items="paginatedUsers"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      :key="searchQuery"
    >
      <template #cell(projectRole)="row">
        {{ getRoleForSelectedProject(row.item) }}
      </template>
      <template #cell(actions)="row"  v-if="(current_role === 'Administrator') || (current_role === 'Administrator')" >
        <div class="d-flex align-items-center" v-if="!(current_role === 'Administrator' && row.item._id === user.id)">
          <b-button
            class="btn-yellow color-black rounded-pill"
            size="sm"
            @click="editMember(row.item)"
            >
            <i class="fas fa-pen"></i>
          </b-button>
          <b-button
            size="sm"
            class="ml-1 rounded-pill delete-btn"
            @click="openDeleteModal(row.item)"
          >
            <i class="fas fa-trash"></i>
          </b-button>
        </div>
      </template>

      <template #row="{ item }">
        <b-tr :class="{ 'grayed-out-row': isUserInProject(item) }">
          <b-td>{{ item.personal.fullname }}</b-td>
          <b-td>{{ item.personal.qualification }}</b-td>
          <b-td>{{ item.personal.email }}</b-td>
          <b-td>{{ item.personal.phone }}</b-td>
          <b-td>{{ item.company.name }}</b-td>
          <b-td>{{ getRoleForSelectedProject(item) }}</b-td> Display the role
          correctly
          <b-td class="text-center">
            <slot name="cell(actions)" v-bind="{ item }" ></slot>
          </b-td>
        </b-tr>
      </template>
    </b-table></div>
    <b-pagination
      v-if="!loading"
      v-model="currentPage"
      :total-rows="filteredUsers.length"
      :per-page="perPage"
      align="center"
      class="my-2"
      size="sm"
    ></b-pagination>
    <div
      v-if="loading"
      class="text-center d-flex justify-content-center align-items-center h-100"
    >
      <b-spinner scale="4" class="color-yellow"></b-spinner>
      <div class="ml-2">Loading...</div>
    </div>

    <!-- UpdateMembers modal -->
    <b-modal
      v-if="selectedMember"
      v-model="isUpdateModalOpen"
      @hidden="resetModal"
      centered
      header-close-content="<i class='fa fa-times color-white'></i>"
      hide-footer
    >
      <template #modal-title>
        <h4 class="mx-1 text-white">Update Member</h4>
      </template>
      <UpdateMembers :member="selectedMember" @close-modal="closeUpdateModal" />
    </b-modal>

    <b-modal
      v-if="selectedMember"
      v-model="isDeleteModalOpen"
      header-close-content="<i class='fas fa-times color-white'></i>"
      @hidden="resetModal"
      centered
      hide-footer
    >
      <template #modal-title>
        <h4 class="text-white">Remove Member</h4>
      </template>
      <DeleteUserConfirmation
        :member="selectedMember"
        @delete-success="handleDeleteSuccess"
        @cancel-deletion="closeDeleteModal"
        @close-modal="closeDeleteModal"
      />
    </b-modal>

    <!-- AddMembers modal -->
    <b-modal
      v-model="isAddMembersModalOpen"
      centered
      @hidden="resetModal"
      size="xl"
      hide-footer
      header-close-content="<i class='fas fa-times color-white'></i>"
    >
      <template #modal-title>
        <h4 class="ml-4 text-white">Add Members to Project</h4>
      </template>
      <AddMembers @close-add-member="closeAddMemberModal" />
    </b-modal>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UpdateMembers from './UpdateMembers.vue'
import DeleteUserConfirmation from './DeleteUserConfirmation.vue'
import AddMembers from './AddMembers.vue'

export default {
  components: {
    UpdateMembers,
    DeleteUserConfirmation,
    AddMembers
  },

  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchQuery: '',
      tableFields: [
        { key: 'personal.fullname', label: 'Full Name' },
        { key: 'personal.qualification', label: 'Qualification' },
        { key: 'personal.email', label: 'Email' },
        { key: 'personal.phone', label: 'Phone' },
        { key: 'company.name', label: 'Company' },
        { key: 'projectRole', label: 'Role', formatter: this.formatRoles },
        { key: 'actions', label: 'Actions' }
      ],
      selectedMember: null,
      currentPage: 1,
      perPage: 10,
      isUpdateModalOpen: false,
      isDeleteModalOpen: false,
      isAddMembersModalOpen: false,
      rerender: 0
    }
  },
  computed: {
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('projectSubmission', ['selectedProjectId']),
    ...mapState('auth', ['user']),
    ...mapState('license', ['info']),
    check_loading () {
      return this.loading
    },
    current_role () {
      const currentMem = this.members.find(p => p._id === this.user.id)
      if (currentMem) {
        const currentPro = currentMem.projectRole.find(p => p.project === this.selectedProjectId)
        if (currentPro) {
          // console.log('member restrict1', currentPro.role)
          return currentPro.role
        } else {
          // console.log('member restrict2', 'Administrator')
          return 'Administrator'
        }
      } else {
        return 'View Only'
      }
    },
    filteredUsers () {
      if (!this.searchQuery) {
        return this.members
      }
      const query = this.searchQuery.toLowerCase()

      return this.members.filter((member) => {
        const fullname = member.personal.fullname.toLowerCase()
        const qualification = member.personal.qualification.toLowerCase()
        const email = member.personal.email.toLowerCase()
        const phone = member.personal.phone.toLowerCase()
        const companyName = member.company.name.toLowerCase()

        const selectedProjectRole = member.projectRole.find(
          (roleObj) => roleObj.project === this.selectedProjectId
        )

        // // // // console.log(member.projectRole.find(
        //   (roleObj) => roleObj.project === this.selectedProjectId
        // ))

        const roleForSelectedProject = selectedProjectRole.role
          ? selectedProjectRole.role
          : 'View Only'

        return (
          fullname.includes(query) ||
          qualification.includes(query) ||
          email.includes(query) ||
          phone.includes(query) ||
          companyName.includes(query) ||
          roleForSelectedProject.toLowerCase().includes(query) // Search by roles
        )
      })
    },
    paginatedUsers () {
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      return this.filteredUsers.slice(startIndex, endIndex)
    }
  },
  watch: {
    check_loading (_new) {
      this.rerender += 1
    }
  },
  methods: {
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('projectSubmission', ['getUserProjects']),
    editMember (member) {
      this.selectedMember = member
      this.isUpdateModalOpen = true
    },
    closeUpdateModal () {
      this.isUpdateModalOpen = false
      if (this.projectId) {
        this.getUserProjects()
        this.getMembers(this.projectId)
      }
    },
    searchUsers () {
      this.currentPage = 1
    },
    clearSearch () {
      this.searchQuery = ''
      this.currentPage = 1
    },
    isUserInProject (user) {
      return this.project.users.some(
        (projectUser) => projectUser._id === user._id
      )
    },
    resetModal () {
      this.selectedMember = null
      this.isUpdateModalOpen = false
    },
    openDeleteModal (member) {
      this.selectedMember = member
      this.isDeleteModalOpen = true
    },
    closeDeleteModal () {
      this.isDeleteModalOpen = false
      if (this.projectId) {
        this.getMembers(this.projectId)
        this.getUserProjects()
      }
    },
    handleDeleteSuccess () {
      this.isUpdateModalOpen = false
      this.isDeleteModalOpen = false
      this.resetModal()
    },
    openAddMembers () {
      this.isAddMembersModalOpen = true
    },
    closeAddMemberModal () {
      this.isAddMembersModalOpen = false
      if (this.projectId) {
        this.getMembers(this.projectId)
        this.getUserProjects()
      }
    },
    formatRoles (value) {
      return value.map((roleObj) => roleObj.role).join(', ')
    },
    getRoleForSelectedProject (user) {
      const selectedProjectRole = user.projectRole.find(
        (roleObj) => roleObj.project === this.selectedProjectId
      )
      return selectedProjectRole ? selectedProjectRole.role : 'Administrator'
    }
  },
  mounted () {
    if (this.projectId) {
      this.getMembers(this.projectId)
    }
  }
}
</script>

<style scoped>
.grayed-out-row {
  opacity: 0.5;
}

.delete-btn{
  background-color: rgb(177, 20, 20);
  border-color: rgb(177, 20, 20);
}

.search {
  background-color: #081624;
  border-color: #1b4168;
}
</style>
